







































import {Emit} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {BoisSize} from '@/models';

import CrudBoisSizeEdit from '@/components/Crud/BoisSize/Edit.vue';

@Component({
	components: {
		CrudBoisSizeEdit,
	}
})
export default class CrudBoisSizeEditDialog extends Vue {
	@InOut({ type: BoisSize  , isVModel: true }) private value!: BoisSize;

	@Emit()
	private onSubmit(boisSize: BoisSize) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}
}
