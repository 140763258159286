




































































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {Parcelle} from '@/models';

import storeParcelle, {ParcelleFilter} from '@/stores/modules/parcelle';

import CrudParcelleFilter from '@/components/Crud/Parcelle/Filter.vue';
import CrudParcelleShowDialog from '@/components/Crud/Parcelle/ShowDialog.vue';
import CrudParcelleEditDialog from '@/components/Crud/Parcelle/EditDialog.vue';
import CrudParcelleList from '@/components/Crud/Parcelle/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

@Component({
	components: {
		CrudParcelleFilter,
		CrudParcelleShowDialog,
		CrudParcelleEditDialog,
		CrudParcelleList,
		CrudDeleteDialog,
	}
})
export default class ParcelleView extends Vue {

	@Stored(() => storeParcelle)
	private filters!: ParcelleFilter;

	@Stored(() => storeParcelle)
	private parcelles!: ResultType<Parcelle>;

	private showParcelle: Parcelle = null;
	private editParcelle: Parcelle = null;
	private deletedParcelle: Parcelle = null;
	private storeParcelle = storeParcelle;

	public mounted(): void {
		this.filters.clear();
	}

	@Emit()
	private onCreate(): void {
		this.editParcelle = new Parcelle();
	}

	private refresh(): void {
		storeParcelle.dispatch('getC', { filters: true });
	}
}
