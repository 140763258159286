

































































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {Marquage} from '@/models';

import CrudMarquageFilter from '@/components/Crud/Marquage/Filter.vue';
import CrudMarquageEditDialog from '@/components/Crud/Marquage/EditDialog.vue';
import CrudMarquageList from '@/components/Crud/Marquage/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storeMarquage from '@/stores/modules/marquage';

@Component({
	components: {
		CrudMarquageFilter,
		CrudMarquageEditDialog,
		CrudMarquageList,
		CrudDeleteDialog,
	}
})
export default class MarquageView extends Vue {
	
	@Stored(() => storeMarquage)
	private marquages!: ResultType<Marquage>;
	
	private editMarquage: Marquage = null;
	private deletedMarquage: Marquage = null;
	private storeMarquage = storeMarquage;
	
	@Emit()
	private onCreate(): void {
		this.editMarquage = new Marquage();
	}

	private refresh(): void {
		storeMarquage.dispatch('getC', { filters: true });
	}
}
