







































	import {Prop, Watch} from 'vue-property-decorator';
	import {Emit} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';

	import {Livraison, Chantier} from '@/models';

	import CrudLivraisonEditCreate from '@/components/Crud/Livraison/EditCreate.vue';

	@Component({
		components: {
			CrudLivraisonEditCreate,
		}
	})
	export default class CrudLivraisonEditDialogCreate extends Vue {
		@InOut({ type: Livraison  , isVModel: true }) private value!: Livraison;

		@Emit()
		private onSubmit(livraison: Livraison) {
			this.onClose();
		}

		@Emit()
		private onClose() {
			this.value = null;
		}

}
