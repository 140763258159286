







































import {Emit} from 'vue-property-decorator';
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Tiers, TiersType} from '@/models';

import CrudTiersEditAttestation from '@/components/Crud/Tiers/EditAttestation.vue';

@Component({
	components: {
		CrudTiersEditAttestation,
	}
})
export default class CrudTiersEditDialog extends Vue {
	@InOut({ type: Tiers  , isVModel: true }) private value!: Tiers;

	TiersType = TiersType;

	@Emit()
	private onSubmit(tiers: Tiers) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}
}
