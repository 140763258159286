








































































import {Prop, Watch} from "vue-property-decorator";
import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {BuyType, Contrat, CVO, PaymentType, Prestation} from '@/models';

import storePrestation from '@/stores/modules/prestation';

import InputDatePicker from '@/components/Input/DatePicker.vue';
import InputPrestationType from '@/components/Input/PrestationType.vue';
import InputEnumSelect from '@/components/Input/EnumSelect.vue';
import InputDepositList from '@/components/Input/DepositList.vue';
import CrudContratSearch from '@/components/Crud/Contrat/Search.vue';
import CrudParcelleSearch from '@/components/Crud/Parcelle/Search.vue';
import CrudChantierSearch from '@/components/Crud/Chantier/Search.vue';

@Component({
	components: {
		InputDatePicker,
		InputPrestationType,
		InputEnumSelect,
		InputDepositList,
		CrudContratSearch,
		CrudParcelleSearch,
		CrudChantierSearch,
	}
})
export default class CrudPrestationEditAlerte extends AbstractEditVue<Prestation> {

	private BuyType = BuyType;
	private CVO = CVO;
	private PaymentType = PaymentType;

	@Prop({ type: Contrat, default: null}) contrat: Contrat;

	public constructor() {
		super(
			storePrestation,
			'Prestation modifié',
			'Prestation créé',
		);
	}

	public async mounted(): Promise<void> {
		await super.mounted();
	
	}
}
