import Vue from 'vue';
import Vuex, {Store} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {NumberFilter} from '@/shared/filter';
import {DateFilter} from '@/shared/filter';
import {Tiers} from '@/models';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {BoisQuality, BoisSize, BoisType, Plateforme, PlateformeCase, LivraisonPlateformePourChauffeur, Marquage} from '@/models';
import {LivraisonPlateformePourChauffeurService} from '@/xhttp';
import store from '@/stores';


Vue.use(Vuex);

export class LivraisonPlateformePourChauffeurFilter {

	public plateforme:   Plateforme[] = [];
	public boisType:     BoisType[] = [];
	public boisQuality:  BoisQuality[] = [];
	public boisSize:     BoisSize[] = [];

	public clear(): void {
		this.plateforme = [];
		this.boisType = [];
		this.boisQuality = [];
		this.boisSize = [];
	}

	public toString(): string {
		return JSON.stringify({
			plateforme    : this.plateforme.map(b => b.id),
			boisType    : this.boisType.map(b => b.id),
			boisQuality : this.boisQuality.map(b => b.id),
			boisSize    : this.boisSize.map(b => b.id),
		});
	}
}

class LivraisonPlateformePourChauffeurState extends PaginatorFilterState<LivraisonPlateformePourChauffeurFilter> {
	public livraisonPlateformePourChauffeur: LivraisonPlateformePourChauffeur = null;
	public livraisonPlateformePourChauffeurs: ResultType<LivraisonPlateformePourChauffeur> = null;
	public constructor() {
		super(new LivraisonPlateformePourChauffeurFilter(), 'livraisonPlateformePourChauffeurs', 'livraisonPlateformePourChauffeur', 'id', AscDesc.DESC, 20);
	}
}

class LivraisonPlateformePourChauffeurStore {

	@XHTTPService(() => LivraisonPlateformePourChauffeur)
	private livraisonPlateformePourChauffeurService: LivraisonPlateformePourChauffeurService;
	private  _store: Store<any>;

	public state: LivraisonPlateformePourChauffeurState = new LivraisonPlateformePourChauffeurState();

	public mutations = {
		...PaginatorMutationFilter(() => this._store, 'livraisonPlateformePourChauffeurs', 'livraisonPlateformePourChauffeur'),
	};

	public actions = { 
		...PaginatorActionWithFilters<LivraisonPlateformePourChauffeur, LivraisonPlateformePourChauffeurState>(() => LivraisonPlateformePourChauffeur, 'livraisonPlateformePourChauffeurs', 'livraisonPlateformePourChauffeur'),
		...StandardAction<LivraisonPlateformePourChauffeur, LivraisonPlateformePourChauffeurState>(() => LivraisonPlateformePourChauffeur, 'livraisonPlateformePourChauffeur'),
	};

	public init(store: Store<any>): this {
		this._store = store;
		return this;
	}
}

const instance = new LivraisonPlateformePourChauffeurStore();
const storeLivraisonPlateformePourChauffeur = new Vuex.Store(instance);
instance.init(storeLivraisonPlateformePourChauffeur);

store.registerModule('livraisonPlateformePourChauffeur', storeLivraisonPlateformePourChauffeur);
export default storeLivraisonPlateformePourChauffeur;

