






































































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Abatteur} from '@/models';

import storeAbatteur from '@/stores/modules/abatteur';

import InputPhone from '@/components/Input/Phone.vue';

@Component({
	components: {
		InputPhone,
	}
})
export default class CrudAbatteurEdit extends AbstractEditVue<Abatteur> {
	public constructor() {
		super(
			storeAbatteur,
			'Abatteur modifié',
			'Abatteur créé',
		);
	}
}
