






































































































































































import {Prop, Watch} from "vue-property-decorator";
import {Emit} from "vue-property-decorator";
import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {TiersType, BoisQuality, BoisSize, BoisType, LivraisonPlateformePourChauffeur, Marquage} from '@/models';
import {ObjectHelper} from "@/shared/utils";
import InputDatePicker from '@/components/Input/DatePicker.vue';

import storeLivraisonPlateformePourChauffeur from '@/stores/modules/livraisonPlateformePourChauffeur';

import InputBoisType from '@/components/Input/BoisType.vue';
import InputBoisQuality from '@/components/Input/BoisQuality.vue';
import InputBoisSize from '@/components/Input/BoisSize.vue';
import InputMarquage from '@/components/Input/Marquage.vue';
import boisQuality from '@/router/boisQuality';
import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';
import storePlateforme from "@/stores/modules/plateforme";
import storePlateformeCase,  {PlateformeCaseFilter} from "@/stores/modules/plateformeCase";

@Component({
	components: {
		InputBoisType,
		InputBoisQuality,
		InputBoisSize,
		InputMarquage,
		CrudTiersSearch,
	}
})
export default class CrudLivraisonPlateformePourChauffeurEdit extends AbstractEditVue<LivraisonPlateformePourChauffeur> {
	
	private TiersType = TiersType;
	private storePlateforme = storePlateforme;
	private storePlateformeCase = storePlateformeCase;
	private step: number = 1;
	private nextdisabled: Boolean = true;
	private volumeSteres: number = 0;

	public constructor() {
		super(
			storeLivraisonPlateformePourChauffeur,
			'Livraison modifié',
			'Livraison créé',
		);
	}

	public async mounted(): Promise<void> {
		await storePlateforme.dispatch('getAll');
		await super.mounted();
	}

	protected async refresh(): Promise<void> {
		await super.refresh();

		console.log("Refersh");
		this.checkInputs();
	}

	public checkInputs() {
		if (this.item.plateforme && this.item.scierie) { this.nextdisabled = false }
		else { this.nextdisabled = true }
	}

	@Emit()
	private onNext() {
		this.step = this.step + 1;
	}

	@Emit()
	private onPrevious() {
		this.step = this.step - 1;
	}

	private onChangeVolumeInner(): void {
		if (this.item.volume) {
			this.volumeSteres = Math.round(this.item.volume / 0.63 * 100 ) / 100;
		}
		this.$forceUpdate();
	}

	private onChangeVolumeStere(): void {
		if (this.volumeSteres) {
			this.item.volume = Math.round(this.volumeSteres * 0.63 * 100 ) / 100;
		}
		this.$forceUpdate();
	}
}
