

















import {Prop} from "vue-property-decorator";
import {ObjectConstructor} from 'gollumts-objecttype';
import {Component, Vue} from '@/shared/component';

import {Contact} from '@/models';

import ListReduce from '@/components/Crud/ListReduce.vue';
import CrudContactShow from '@/components/Crud/Contact/Show.vue';

@Component({
	components: {
		ListReduce,
	}
})
export default class CrudContactListReduce extends Vue {
	@Prop({ type: Array }) private items!: Contact[];
	private showComponent: ObjectConstructor<Vue> = CrudContactShow;
}
