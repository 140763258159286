





































































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {Contrat} from '@/models';

import CrudContratFilter from '@/components/Crud/Contrat/Filter.vue';
import CrudContratEditDialog from '@/components/Crud/Contrat/EditDialog.vue';
import CrudContratList from '@/components/Crud/Contrat/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storeContrat, {ContratFilter} from '@/stores/modules/contrat';

@Component({
	components: {
		CrudContratFilter,
		CrudContratEditDialog,
		CrudContratList,
		CrudDeleteDialog,
	}
})
export default class ContratView extends Vue {

	@Stored(() => storeContrat)
	private contrats!: ResultType<Contrat>;

	@Stored(() => storeContrat)
	private filters!: ContratFilter;

	private editContrat: Contrat = null;
	private deletedContrat: Contrat = null;
	private storeContrat = storeContrat;

	public mounted(): void {
		this.filters.clear();
	}

	private refresh(): void {
		storeContrat.dispatch('getC', { filters: true });
	}
}
