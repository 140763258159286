












































































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {Purchase} from '@/models';

import CrudPurchaseFilter from '@/components/Crud/Purchase/Filter.vue';
import CrudPurchaseEditDialog from '@/components/Crud/Purchase/EditDialog.vue';
import CrudPurchaseList from '@/components/Crud/Purchase/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storePurchase from '@/stores/modules/purchase';

@Component({
	components: {
		CrudPurchaseFilter,
		CrudPurchaseEditDialog,
		CrudPurchaseList,
		CrudDeleteDialog,
	}
})
export default class PurchaseView extends Vue {

	@Stored(() => storePurchase)
	private purchases!: ResultType<Purchase>;

	private editPurchase: Purchase = null;
	private isNew: boolean = false;
	private deletedPurchase: Purchase = null;
	private storePurchase = storePurchase;

	@Emit()
	private onCreate(): void {
		this.editPurchase = new Purchase();
		this.isNew = true;
	}

	private refresh(): void {
		this.isNew = false;
		storePurchase.dispatch('getC', { filters: true });
	}
}
