















































































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {Chauffeur} from '@/models';

import CrudChauffeurFilter from '@/components/Crud/Chauffeur/Filter.vue';
import CrudChauffeurShowDialog from '@/components/Crud/Chauffeur/ShowDialog.vue';
import CrudChauffeurEditDialog from '@/components/Crud/Chauffeur/EditDialog.vue';
import CrudChauffeurList from '@/components/Crud/Chauffeur/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storeChauffeur, {ChauffeurFilter} from '@/stores/modules/chauffeur';

@Component({
	components: {
		CrudChauffeurFilter,
		CrudChauffeurShowDialog,
		CrudChauffeurEditDialog,
		CrudChauffeurList,
		CrudDeleteDialog,
	}
})
export default class ChauffeurView extends Vue {
	
	@Stored(() => storeChauffeur)
	private chauffeurs!: ResultType<Chauffeur>;
	
	@Stored(() => storeChauffeur)
	private filters!: ChauffeurFilter;

	private showChauffeur: Chauffeur = null;
	private editChauffeur: Chauffeur = null;
	private deletedChauffeur: Chauffeur = null;
	private storeChauffeur = storeChauffeur;

	@Emit()
	private onCreate(): void {
		this.editChauffeur = new Chauffeur();
	}

	public mounted(): void {
		console.log("Mounted chauffeur");
		this.filters.clear();
	}

	private refresh(): void {
		storeChauffeur.dispatch('getC', { filters: true });
	}
}
