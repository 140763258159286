





































































































































































































































































































































































































































































































































































































    import {Emit} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {Stored} from "vue-stored-prop-decorator";
	import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';
    import {Chantier, Token, User} from "@/models";
	import Checked from '@/components/Checked.vue';

	import storeChantier from '@/stores/modules/chantier';
	import storeLoader from "@/stores/modules/loader";
	import storeToken from "@/stores/modules/token";
	import storeUser from '@/stores/modules/user';

	import CrudChantierEditDialogDonneurOrdre from '@/components/Crud/Chantier/EditDialogDonneurOrdre.vue';
	import CrudChantierEditDialogAbattage from '@/components/Crud/Chantier/EditDialogAbattage.vue';
	import CrudChantierEditDialogDebardage from '@/components/Crud/Chantier/EditDialogDebardage.vue';
	import CrudChantierEditDialogDetails from '@/components/Crud/Chantier/EditDialogDetails.vue';
	import CrudChantierEditDialogTerrain from '@/components/Crud/Chantier/EditDialogTerrain.vue';
	import CrudDocumentChantierUploader from '@/components/Crud/DocumentChantier/Uploader.vue';
	import CrudDocumentChantierShow from '@/components/Crud/DocumentChantier/Show.vue';

	@Component({
		components: {
			CrudChantierEditDialogDonneurOrdre,
			CrudChantierEditDialogAbattage,
			CrudChantierEditDialogDebardage,
			CrudChantierEditDialogDetails,
			CrudChantierEditDialogTerrain,
			CrudDocumentChantierUploader,
			CrudDocumentChantierShow,
			Checked,
		}
	})
	export default class CrudChantierFicheShow extends AbstractShowVue<Chantier> {

		@Stored(() => storeLoader)     private loading: boolean;
        @Stored(() => storeToken)      private token: Token;
		@Stored(() => storeUser) me!: User;

		private editChantierDonneurOrdre: Chantier = null;
		private editChantierAbattage: Chantier = null;
		private editChantierDebardage: Chantier = null;
		private editChantierDetails: Chantier = null;
		private editChantierTerrain: Chantier = null;
		
		private get hrefPdf(): string {
			return `${process.env.VUE_APP_BACK_BASE_URL}/pdf/chantier/${this.item.id}?token=${this.token.id}`;
		}

		public constructor() {
			super(storeChantier);
		}
		
	}
