
































import {Emit} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Tiers} from '@/models';

import CrudTiersListPrixM3 from '@/components/Crud/Tiers/ListPrixM3.vue';

@Component({
	components: {
		CrudTiersListPrixM3,
	}
})
export default class CrudTiersShowDialog extends Vue {
	@InOut({ type: Tiers  , isVModel: true }) private value!: Tiers;
	
	@Emit()
	public onClose() {
		this.value = null;
	}
}
