


































	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';

	import storePrestation from '@/stores/modules/prestation';

	import InputReglementsList from '@/components/Input/ReglementsList.vue';
    import {Prestation} from "@/models";

	@Component({
		components: {
            InputReglementsList,
		}
	})
	export default class CrudPrestationEditReglement extends AbstractEditVue<Prestation> {
		public constructor() {
			super(
				storePrestation,
				'Réglement modifié',
				'Réglement créé',
			);
		}

	}
