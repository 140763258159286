
















































































































































































































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Chantier} from '@/models';

import storeChantier from '@/stores/modules/chantier';


@Component({
	components: {
	}
})
export default class CrudChantierEditTerrain extends AbstractEditVue<Chantier> {
	public constructor() {
		super(
			storeChantier,
			'Infos spécifiques chantier modifié',
			'Infos spécifiques chantier créé',
		);
	}
}
