








































import {Emit, Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Contrat, Prestation} from '@/models';

import CrudPrestationEdit from '@/components/Crud/Prestation/Edit.vue';

@Component({
	components: {
		CrudPrestationEdit,
	}
})
export default class CrudPrestationEditDialog extends Vue {
	@InOut({ type: Prestation  , isVModel: true }) private value!: Prestation;
	@Prop({ type: Contrat, default: null}) contrat: Contrat;

	@Emit()
	private onSubmit(prestation: Prestation) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		if (
			this.value.id && 
			this.$route.name !== 'prestation-show' && 
			this.$route.params["id"] !== this.value.id.toString()
		) {
			this.$router.push({name: 'prestation-show', params: { id : this.value.id.toString() }});
		}
		this.value = null;
	}
}
