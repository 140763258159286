

































































	import {Prop, Watch} from "vue-property-decorator";
	import {Component, Vue} from '@/shared/component';
	import {Stored} from "vue-stored-prop-decorator";
	import {Chantier, CubagePlateforme, Plateforme} from "@/models";
	import {ArrayHelper} from "@/shared/utils";
  	import Checked from '@/components/Checked.vue';
	import {ResultType} from '@/shared/xhttp';

	import storeCubagePlateforme, {CubagePlateformeFilter} from '@/stores/modules/cubagePlateforme';

	import CrudList from '@/components/Crud/List.vue';
	import TableHead from '@/components/Paginate/TableHead.vue';
	import TableCell from '@/components/Paginate/TableCell.vue';

	@Component({
		components: {
			CrudList,
			TableHead,
			TableCell,
			Checked,
		}
	})
	export default class CrudCubagePlateformeList extends Vue {

		@Prop({ type: Boolean   , default: true  }) public paginate!: boolean;
		@Prop({ type: Boolean   , default: false }) public dense!   : boolean;
		@Prop({ type: [ Plateforme, Boolean ] , default: false }) public plateforme: Plateforme;

		@Stored(() => storeCubagePlateforme) private filters!: CubagePlateformeFilter;
		@Stored(() => storeCubagePlateforme)
		private cubagePlateformes!: ResultType<CubagePlateforme>;
		
		private storeCubagePlateforme = storeCubagePlateforme;

		private totalLivraison: number = 0;
		private totalVentes: number = 0;

		@Watch('cubagePlateformes')
		public calculTotaux(): void {
			//console.log("filter");
			// on calcul le total
			//console.log(this.prestations);
			this.totalLivraison = 0;
			this.totalVentes = 0;
			for (const cubage of this.cubagePlateformes.data) {
				this.totalLivraison = this.totalLivraison + cubage.volumeInner;
				//this.totalVentes = this.totalVentes + cubage.prixCamion;
			}

		}

		public get show(): boolean {
			return this.plateforme !== null;
		}

		public mounted(): void {
			this.watcher()
		}

		@Watch('plateforme')
		public watcher(): void {
			if (this.plateforme) {
				this.filters.clear();
				this.filters.plateforme = [ this.plateforme ];
			} else {
				this.filters.plateforme = [];
			}
		}
	}
