





























































































	import {Prop, Watch} from "vue-property-decorator";
	import {Stored} from 'vue-stored-prop-decorator';
	import {Component, Vue} from '@/shared/component';
	import axios from "axios";
	import storeToken from "@/stores/modules/token";
	import {Token, User, CubagePrestationEstimed} from "@/models";
	import { createStore } from 'devextreme-aspnet-data-nojquery';
    import CrudCubagePrestationEstimedEditDocumentDialog from '@/components/Crud/Prestation/EditDialogDocumentCubage.vue';

	@Component({
		components: {
			CrudCubagePrestationEstimedEditDocumentDialog,
		}
	})
	export default class PrestationAttentesCubagesView extends Vue {

		@Stored(() => storeToken) private token: Token;

        private editCubagePrestationEstimedDocument: CubagePrestationEstimed = null;

		private data = '';
		private headers = [
			{ text: 'Bois', value: 'bois_type', groupable: false, sortable: false },
			{ text: 'Qualité', value: 'bois_quality', groupable: false, sortable: false },
			{ text: 'Taille', value: 'bois_size', groupable: false, sortable: false },
			{ text: 'Marquage', value: 'marquage', groupable: false, sortable: false },
			{ text: 'Volume estimé', value: 'volume', groupable: false, sortable: false },
		];

		private async mounted(): Promise<void> {
			this. LoadData();
		}

		private async LoadData()
		{
			await axios.get(process.env.VUE_APP_BACK_BASE_URL + "/api/prestations/cubages_attentes?token=" + this.token.id, {}).then(async response => {
				this.data = await response.data;
			});
		}

		public onClickDocs(id)
		{
			 var oCubage = new CubagePrestationEstimed();
			 oCubage.id = id;
			 this.editCubagePrestationEstimedDocument = oCubage;
		}

		itemRowBackground (item){
			if (item.boisVendu == 1 )
			{
				return item.grume == 1 ? 'grume_vendu_class' : 'billon_vendu_class';
			}
			else
			{
				return item.grume == 1 ? 'grume_class' : 'billon_class';
			}
		}
	}
