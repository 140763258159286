import { render, staticRenderFns } from "./EditDispatchDialog.vue?vue&type=template&id=4f135248&"
import script from "./EditDispatchDialog.vue?vue&type=script&lang=ts&"
export * from "./EditDispatchDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports