

























































































import {Prop} from "vue-property-decorator";
import {InOut} from "vue-inout-prop-decorator"
import {Component, Vue} from '@/shared/component';
import {Form} from "@/shared/form";
import {SuiviReglement, ReglementType} from "@/models";

import LoopForm from "@/shared/form/LoopForm.vue";

import InputEnumSelect from '@/components/Input/EnumSelect.vue';
import InputDatePicker from './DatePicker.vue';

@Component({
	components: {
		LoopForm,
		InputEnumSelect,
		InputDatePicker,
	}
})
export default class InputReglementsList extends Vue {
	
	@InOut({ type: Array  , isVModel: true }) private value!: SuiviReglement[];

	private ReglementType = ReglementType;

	private get form(): Form {
		return Form.searchParentForm(this);
	}

	private get formName(): string {
		return Form.searchParentName(this);
	}
	
	private clearForm(): void {
		if (this.form) {
			this.form.clearErrors(this.formName);
			this.form.clearErrors(new RegExp('^priceM3s\\[\\d\\]'));
		}
	}
	
	private add(): void {
		this.clearForm();
		this.value.push(new SuiviReglement());
		this.$emit('input', this.value);
	}
	
	private remove(index: number): void {
		this.value.splice(index, 1);
	}
}
