import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import {ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutation, SetterMutation,
	StandardAction
} from '@/shared/utils';
import {DateFilter} from '@/shared/filter';
import {Contrat, PlateformeCase, Tiers} from '@/models';
import {PlateformeCaseService} from '@/xhttp';
import store from '@/stores';


Vue.use(Vuex);

export class PlateformeCaseFilter {


	public clear(): void {
	}

	public toString(): string {
		return JSON.stringify({
		});
	}
}

class PlateformeCaseState extends PaginatorFilterState<PlateformeCaseFilter> {
	public plateformeCase: PlateformeCase = null;
	public last: PlateformeCase = null;
	public plateformeCases: ResultType<PlateformeCase> = null;
	public constructor() {
		super(new PlateformeCaseFilter(), 'plateformeCases', 'plateformeCase');
	}
}

class PlateformeCaseStore {

	@XHTTPService(() => PlateformeCase)
	private plateformeCaseService: PlateformeCaseService;

	public state: PlateformeCaseState = new PlateformeCaseState();

	public mutations = {
		...PaginatorMutation(() => storePlateformeCase, 'plateformeCases', 'plateformeCase'),
		...SetterMutation([ 'last' ])
	};

	public actions = {
		...StandardAction<PlateformeCase, PlateformeCaseState>(() => PlateformeCase, 'plateformeCase'),
		...PaginatorActionWithFilters<PlateformeCase, PlateformeCaseState>(() => PlateformeCase, 'plateformeCases', 'plateformeCase'),
	};

}
const instance = new PlateformeCaseStore();
const storePlateformeCase = new Vuex.Store(instance);
store.registerModule('plateformeCase', storePlateformeCase);
export default storePlateformeCase;
