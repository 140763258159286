















































































	import {Prop, Watch} from "vue-property-decorator";
	import {Stored} from 'vue-stored-prop-decorator';
	import {Component, Vue} from '@/shared/component';
	import {ResultType} from '@/shared/xhttp';

	import storeChantier from '@/stores/modules/chantier';
	import {Chantier} from '@/models';

  	import Checked from '@/components/Checked.vue';
	import CrudList from '@/components/Crud/List.vue';
	import TableHead from '@/components/Paginate/TableHead.vue';
	import TableCell from '@/components/Paginate/TableCell.vue';

	@Component({
		components: {
      		Checked,
			CrudList,
			TableHead,
			TableCell,
		}
	})
	export default class CrudChantierList extends Vue {

		@Prop({type: Boolean, default: true  }) private paginate!: boolean;
		@Prop({type: Boolean, default: false }) private dense!   : boolean;
		@Stored(() => storeChantier)
		private chantiers!: ResultType<Chantier>;

		private storeChantier = storeChantier;

		private totalAchats: number = 0;
		private totalCouts: number = 0;
		private totalVentes: number = 0;
		private totalBenefices: number = 0;

		@Watch('chantiers')
		public watcher(): void {
			//console.log("filter");
			// on calcul le total
			//console.log(this.prestations);
			this.totalAchats = 0;
			this.totalCouts = 0;
			this.totalVentes = 0;
			this.totalBenefices = 0;
			for (const chantier of this.chantiers.data) {
				this.totalAchats = this.totalAchats + chantier.totalAchatsBois;
				this.totalCouts = this.totalCouts + chantier.totalCoutsExploitation;
				this.totalVentes = this.totalVentes + chantier.totalVentes;
				this.totalBenefices = this.totalBenefices + chantier.benefices;
			}

		}

	}
