












































































import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';

import storePlateforme from '@/stores/modules/plateforme';

import InputDateFilter from '@/components/Input/DateFilter.vue';
import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';
import TrueFalseMultiSelect from '@/components/Input/TrueFalseMultiSelect.vue';

@Component({
	components: {
		InputDateFilter,
		CrudTiersSearch,
		TrueFalseMultiSelect,
	}
})
export default class CrudPlateformeFilter extends Vue {

	@Prop({ type: Boolean, default: false}) reduce;

	@Stored(() => storePlateforme) private page!: number;
	//@Stored(() => storePlateforme) private filters!: PlateformeFilter;

	//@WatchObjectProperties('filters', PlateformeFilter)
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		storePlateforme.dispatch('getC', { filters: true });
	}

	public mounted() {
		//this.filters = new PlateformeFilter();
	}
}
