





















































































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Chantier} from '@/models';

import storeChantier from '@/stores/modules/chantier';

import InputDatePicker from '@/components/Input/DatePicker.vue';
import CrudContactSearch from '@/components/Crud/Contact/Search.vue';

@Component({
	components: {
		InputDatePicker,
		CrudContactSearch,
	}
})
export default class CrudChantierEditAbattage extends AbstractEditVue<Chantier> {
	public constructor() {
		super(
			storeChantier,
			'Intervenant Abattage modifié',
			'Intervenant Abattage créé',
		);
	}
}
