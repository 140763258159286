































import {Prop} from "vue-property-decorator";
import {Stored} from "vue-stored-prop-decorator";
import {Component} from '@/shared/component';
import {AbstractSearchVue} from '@/components/Abstract/AbstractSearchVue';

import storeParcelle, {ParcelleFilter} from "@/stores/modules/parcelle";

import InputSearchEntity from '@/components/Input/SearchEntity.vue';
import CrudParcelleFilter from '@/components/Crud/Parcelle/Filter.vue';
import CrudParcelleList from '@/components/Crud/Parcelle/List.vue';
import CrudParcelleEditDialog from '@/components/Crud/Parcelle/EditDialog.vue';
import {Parcelle} from "@/models";

@Component({
	components: {
		InputSearchEntity,
		CrudParcelleFilter,
		CrudParcelleList,
		CrudParcelleEditDialog,
	}
})
export default class CrudParcelleSearch extends AbstractSearchVue {

	@Prop({ type: Boolean, default: false }) canCreate: boolean;

	@Stored(() => storeParcelle)
	private filters!: ParcelleFilter;

	private filterComponent = CrudParcelleFilter;
	private listComponent = CrudParcelleList;
	private editParcelle: Parcelle = null;

	public mounted(): void {
		this.filters.clear();
	}

	private onCreate(): void {
		this.editParcelle = new Parcelle();
	}
}
