







































































































































































































































































































































































	import {Emit} from "vue-property-decorator";
	import {Stored} from "vue-stored-prop-decorator";
	import {Component} from '@/shared/component';
	import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';
  	import {Contrat, ContratState, Prestation, Token, PrestationType} from '@/models';
	import Checked from '@/components/Checked.vue';

	import storeContrat from '@/stores/modules/contrat';
	import storeToken from "@/stores/modules/token";
	import storePrestation, {PrestationFilter, PrestationState} from '@/stores/modules/prestation';

	import CrudContratEditDialog from '@/components/Crud/Contrat/EditDialog.vue';
	import CrudContratEditDialogAttestation from '@/components/Crud/Contrat/EditDialogAttestation.vue';
	import CrudDocumentUploader from '@/components/Crud/Document/Uploader.vue';
	import CrudDocumentShow from '@/components/Crud/Document/Show.vue';
	import CrudPrestationFilter from '@/components/Crud/Prestation/Filter.vue';
	import CrudPrestationList from '@/components/Crud/Prestation/List.vue';
	import CrudPrestationEditDialog from '@/components/Crud/Prestation/EditDialog.vue';
	import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';
	import CrudTiersShowReduce from '@/components/Crud/Tiers/ShowReduce.vue';

	@Component({
		components: {
			CrudContratEditDialog,
			CrudContratEditDialogAttestation,
			CrudDocumentUploader,
			CrudDocumentShow,
			CrudPrestationList,
			CrudPrestationFilter,
			CrudPrestationEditDialog,
			CrudDeleteDialog,
			CrudTiersShowReduce,
			Checked,
		}
	})
	export default class CrudContratShow extends AbstractShowVue<Contrat> {

		private editContrat: Contrat = null;
		private editContratAttestation: Contrat = null;

		private editPrestation: Prestation = null;
		private deletedPrestation: Prestation = null;

		@Stored(() => storeToken)
		private token: Token;

		private storePrestation = storePrestation;
		
		private ContratState = ContratState;

		public constructor() {
			super(storeContrat);
		}

		public created(): void {
		}

		@Emit()
		public onAttestation(): void {
			window.open(`${process.env.VUE_APP_BACK_BASE_URL}/pdf/contrat_attestation/${this.item.id}?token=${this.token.id}`, '_blank');
		}

		public async mounted(): Promise<void> {
			storePrestation.state.filters.clear();
			await super.mounted();
		}

		private get hrefPdf(): string {
			return `${process.env.VUE_APP_BACK_BASE_URL}/pdf/contrat/${this.item.id}?token=${this.token.id}`;
		}

		@Emit()
		private onCreatePrestation(): void {
			this.editPrestation = new Prestation();
			this.editPrestation.contrat = this.item;
		}

		private async refreshPrestation(): Promise<void> {
			await Promise.all([
				storePrestation.dispatch('getC', { filters: true }),
				this.refresh(this.value),
			]);
		}

	}
