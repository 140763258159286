import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {XHTTPService} from '@/shared/xhttp';
import {DocumentPrestationCubage} from '@/models';
import {DocumentPrestationCubageService} from '@/xhttp';
import store from '@/stores';
import prestation from "@/router/prestation";
import storePrestation from "@/stores/modules/prestation";

Vue.use(Vuex);

class DocumentPrestationCubageState {
}

class DocumentPrestationCubageStore {

	@XHTTPService(() => DocumentPrestationCubage)
	private documentService: DocumentPrestationCubageService;

	public state: DocumentPrestationCubageState = new DocumentPrestationCubageState();

	public mutations = {
	};

	public actions = {
		async delete(context: ActionContext<DocumentPrestationCubageState, any>, document: DocumentPrestationCubage): Promise<DocumentPrestationCubage> {
			try {
				document.prestation.removeDocumentsCubage(document);
				return await instance.documentService.delete('/{id}', document);
			} catch (e) {
				storePrestation.dispatch('get', document.prestation.id);
				throw e;
			}
		}
	};

}
const instance = new DocumentPrestationCubageStore();
const storeDocumentPrestationCubage = new Vuex.Store(instance);
store.registerModule('document', storeDocumentPrestationCubage);
export default storeDocumentPrestationCubage;
