





























import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';

import storeDebardeur, {DebardeurFilter} from '@/stores/modules/debardeur';

import InputDateFilter from '@/components/Input/DateFilter.vue';
import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';

@Component({
	components: {
		InputDateFilter,
		CrudTiersSearch,
	}
})
export default class CrudDebardeurFilter extends Vue {

	@Prop({ type: Boolean, default: false}) reduce;

	@Stored(() => storeDebardeur) private page!: number;
	@Stored(() => storeDebardeur) private filters!: DebardeurFilter;

	@WatchObjectProperties('filters', DebardeurFilter)
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		storeDebardeur.dispatch('getC', { filters: true });
	}

	public mounted() {
	    this.filters = new DebardeurFilter();
	}
}
