


























import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';

import storeMarquage, {MarquageFilter} from '@/stores/modules/marquage';

import TrueFalseMultiSelect from '@/components/Input/TrueFalseMultiSelect.vue';

@Component({
	components: {
		TrueFalseMultiSelect,
	}
})
export default class CrudMarquageFilter extends Vue {
	
	@Prop({ type: Boolean, default: false}) reduce;
	
	@Stored(() => storeMarquage) private page!: number;
	@Stored(() => storeMarquage) private filters!: MarquageFilter;
	
	@WatchObjectProperties('filters', MarquageFilter)
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		storeMarquage.dispatch('getC', { filters: true });
	}
	
}
