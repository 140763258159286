



















































	import {Prop, Watch} from "vue-property-decorator";
	import {Component, Vue} from '@/shared/component';
	import {Stored} from "vue-stored-prop-decorator";
	import {Plateforme, CubagePlateformeRestant} from "@/models";
	import {ArrayHelper} from "@/shared/utils";
  	import Checked from '@/components/Checked.vue';
	import {ResultType} from '@/shared/xhttp';

	import storeCubagePlateformeRestant, {CubagePlateformeRestantFilter} from '@/stores/modules/cubagePlateformeRestant';

	import CrudList from '@/components/Crud/List.vue';
	import TableHead from '@/components/Paginate/TableHead.vue';
	import TableCell from '@/components/Paginate/TableCell.vue';

	@Component({
		components: {
			CrudList,
			TableHead,
			TableCell,
			Checked,
		}
	})
	export default class CrudCubagePlateformeRestantList extends Vue {

		@Prop({ type: Boolean   , default: true  }) public paginate!: boolean;
		@Prop({ type: Boolean   , default: false }) public dense!   : boolean;
		@Prop({ type: [ Plateforme, Boolean ] , default: false }) public plateforme: Plateforme;

		@Stored(() => storeCubagePlateformeRestant) private filters!: CubagePlateformeRestantFilter;
		@Stored(() => storeCubagePlateformeRestant)
		private cubagePlateformeRestants!: ResultType<CubagePlateformeRestant>;
		
		private storeCubagePlateformeRestant = storeCubagePlateformeRestant;

		private totalPlateformeRestant: number = 0;
		private totalVentes: number = 0;

		@Watch('cubagePlateformeRestants')
		public calculTotaux(): void {
			//console.log("filter");
			// on calcul le total
			//console.log(this.prestations);
			this.totalPlateformeRestant = 0;
			this.totalVentes = 0;

		}

		public get show(): boolean {
			return this.plateforme !== null;
		}

		public mounted(): void {
			this.watcher();
		}

		@Watch('plateforme')
		public watcher(): void {
			if (this.plateforme) {
				this.filters.clear();
				this.filters.plateforme = [ this.plateforme ];
			} else {
				this.filters.plateforme = [];
			}
		}
	}
