var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-crud-cubageLivraison-list-edit",staticStyle:{"margin":"20px"}},[_c('v-row',[_c('h3',[_vm._v("Liste des cubages")])]),_c('v-row',[_c('v-btn',{staticClass:"float-left",attrs:{"color":"primary"},on:{"click":function($event){return _vm.onAddCuabge()}}},[_vm._v(" Ajouter cubage ")])],1),_c('v-row',[(_vm.livraison)?_c('v-data-table',{attrs:{"headers":_vm.headers2,"items":_vm.livraison.cubageLivraisons,"disable-pagination":"","items-per-page":-1,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.chantier",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.chantier.displayName)+" ")]}},{key:"item.bois",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.boisType ? item.boisType.name : '')+"-"+_vm._s(item.boisQuality ? item.boisQuality.name : '')+"-"+_vm._s(item.boisSize ? item.boisSize.name : '')+_vm._s(item.marquage ? '('+item.marquage.name+')' : '')+" ")]}},{key:"item.prix",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.prixCamion ? item.prixCamion+'€' : '')+" "+_vm._s(item.prixM3 ? '('+item.prixM3+'€/m3)' : '')+" ")]}},{key:"item.volume",fn:function(ref){
var item = ref.item;
return [(item.boisSize.grume == true)?_c('span',[_vm._v(" "+_vm._s(item.volumeInner ? item.volumeInner : '')+" m3 ")]):_vm._e(),(item.boisSize.grume !== true)?_c('span',[_vm._v(" "+_vm._s(Number((item.volumeInner) / 0.63).toFixed(2))+" stères "),_c('br'),_vm._v(" ("+_vm._s(item.volumeInner ? item.volumeInner : '0')+" m3) ")]):_vm._e()]}},{key:"item.livre",fn:function(ref){
var item = ref.item;
return [_c('checked',{attrs:{"value":item.boisFini,"label-true":"Tout livré","label-false":"Reste sur chantier"}})]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"icon":"","color":"error","title":"Modifier"},on:{"click":function($event){_vm.editCubageLivraison = item}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}],null,false,4249240136)}):_vm._e(),_c('crud-cubage-livraison-edit-dialog-create',{on:{"on-close":function($event){return _vm.RefreshData()}},model:{value:(_vm.editCubageLivraison),callback:function ($$v) {_vm.editCubageLivraison=$$v},expression:"editCubageLivraison"}})],1),_c('v-row',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',[_c('h3',[_vm._v("Totaux")])]),_c('td',{staticStyle:{"color":"green"}},[_vm._v(_vm._s(Number(_vm.totalLivraisonm3).toFixed(2))+" m3 + "+_vm._s(Number(_vm.totalLivraisonsteres).toFixed(2))+" stères")]),_c('td',{staticStyle:{"color":"green"}},[_vm._v(_vm._s(Number(_vm.totalVentes).toFixed(2))+" €")])])])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }