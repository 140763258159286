













































































import {Prop} from "vue-property-decorator";
import {InOut} from "vue-inout-prop-decorator"
import {Component, Vue} from '@/shared/component';
import {Form} from "@/shared/form";
import {M3Price, M3TypePrice} from "@/models";

import LoopForm from "@/shared/form/LoopForm.vue";

import InputEnumSelect from '@/components/Input/EnumSelect.vue';

@Component({
	components: {
		LoopForm,
		InputEnumSelect,
	}
})
export default class InputM3PriceList extends Vue {
	
	@InOut({ type: Array  , isVModel: true }) private value!: M3Price[];
	@Prop ({ type: Boolean, default: false }) private requiredDtDepot!: boolean;

	private M3TypePrice = M3TypePrice;

	private get form(): Form {
		return Form.searchParentForm(this);
	}

	private get formName(): string {
		return Form.searchParentName(this);
	}
	
	private clearForm(): void {
		if (this.form) {
			this.form.clearErrors(this.formName);
			this.form.clearErrors(new RegExp('^priceM3s\\[\\d\\]'));
		}
	}
	
	private add(): void {
		this.clearForm();
		this.value.push(new M3Price());
		this.$emit('input', this.value);
	}
	
	private remove(index: number): void {
		this.value.splice(index, 1);
	}
}
