





























































import {Component} from '@/shared/component';
import {Abatteur} from '@/models';
import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';

import storeAbatteur from '@/stores/modules/abatteur';

import CrudTiersShow from '@/components/Crud/Tiers/Show.vue';
import CrudTiersEdit from '@/components/Crud/Tiers/Edit.vue';

@Component({
	components: {
		CrudTiersShow,
		CrudTiersEdit,
	}
})
export default class CrudAbatteurShow extends AbstractShowVue<Abatteur> {
	public constructor() {
		super(storeAbatteur);
	}
}
