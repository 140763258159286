


































	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';

	import storePlateforme from '@/stores/modules/plateforme';

	import InputPlateformeCaseList from '@/components/Input/PlateformeCaseList.vue';
    import {Plateforme} from "@/models";

	@Component({
		components: {
            InputPlateformeCaseList,
		}
	})
	export default class CrudPlateformeEditCase extends AbstractEditVue<Plateforme> {
		public constructor() {
			super(
				storePlateforme,
				'Case modifié',
				'Case créé',
			);
		}

	}
