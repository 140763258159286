








































	import {Emit, Prop} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';

	import {Contrat, Tiers} from '@/models';

	import CrudTiersEditPriceM3 from '@/components/Crud/Tiers/EditPriceM3.vue';

	@Component({
		components: {
            CrudTiersEditPriceM3,
		}
	})
	export default class CrudTiersEditDialogPriceM3 extends Vue {
		@InOut({ type: Tiers  , isVModel: true }) private value!: Tiers;

		@Emit()
		private onSubmit(tiers: Tiers) {
			this.onClose();
		}

		@Emit()
		private onClose() {
			this.value = null;
		}
	}
