



































































import {Component} from '@/shared/component';
import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';
import {Tiers} from '@/models';

import storeTiers from '@/stores/modules/tiers';

import CrudAddressShow from '@/components/Crud/Address/Show.vue';
import CrudContactListReduce from '@/components/Crud/Contact/ListReduce.vue';
import CrudContratListReduce from '@/components/Crud/Contrat/ListReduce.vue';

@Component({
	components: {
		CrudAddressShow,
		CrudContactListReduce,
		CrudContratListReduce,
	}
})
export default class CrudTiersShowReduce extends AbstractShowVue<Tiers> {
	public constructor() {
		super(storeTiers);
	}
}
