
































































































import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';
import {Prestation} from "@/models";
import {ArrayHelper} from "@/shared/utils";

import storeCubagePrestationEstimed, {CubagePrestationEstimedFilter} from '@/stores/modules/cubagePrestationEstimed';

import InputNumberFilter from '@/components/Input/NumberFilter.vue';
import InputBoisType from '@/components/Input/BoisType.vue';
import InputBoisQuality from '@/components/Input/BoisQuality.vue';
import InputBoisSize from '@/components/Input/BoisSize.vue';
import InputMarquage from '@/components/Input/Marquage.vue';

@Component({
	components: {
		InputNumberFilter,
		InputBoisType,
		InputBoisQuality,
		InputBoisSize,
		InputMarquage,
	}
})
export default class CrudCubagePrestationFilter extends Vue {
	
	@Prop({ type: Boolean, default: false}) public reduce: boolean;
	@Prop({ type: Prestation, default: null}) public prestation: Prestation;
	
	@Stored(() => storeCubagePrestationEstimed) private page!: number;
	@Stored(() => storeCubagePrestationEstimed) private filters!: CubagePrestationEstimedFilter;
	
	@WatchObjectProperties('filters', CubagePrestationEstimedFilter)
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		storeCubagePrestationEstimed.dispatch('getC', { filters: true });
	}

	public mounted(): void {
		this.filters = new CubagePrestationEstimedFilter();
		this.watcher();
	}

	@Watch('prestation')
	public watcher(): void {
		if (this.prestation) {
			if (!ArrayHelper.equal(this.filters.prestation, [ this.prestation ])) {
				this.filters.prestation = [ this.prestation ];
			}
		} else {
			this.filters.prestation = [];
		}
	}
}
