





























































import {Component} from '@/shared/component';
import {Chauffeur} from '@/models';
import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';

import storeChauffeur from '@/stores/modules/chauffeur';

import CrudTiersShow from '@/components/Crud/Tiers/Show.vue';
import CrudTiersEdit from '@/components/Crud/Tiers/Edit.vue';

@Component({
	components: {
		CrudTiersShow,
		CrudTiersEdit,
	}
})
export default class CrudChauffeurShow extends AbstractShowVue<Chauffeur> {
	public constructor() {
		super(storeChauffeur);
	}
}
