import Vue from 'vue';
import Vuex, {Store} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {NumberFilter} from '@/shared/filter';
import {DateFilter} from '@/shared/filter';
import {Purchase, Tiers} from '@/models';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {BoisQuality, BoisSize, BoisType, Chantier, CubageLivraison, Marquage} from '@/models';
import {CubageLivraisonService} from '@/xhttp';
import store from '@/stores';


Vue.use(Vuex);

export class CubageLivraisonFilter {

	public date: DateFilter = new DateFilter();
	public purchase: Purchase[] = [];
	public hasPurchase: boolean[] = [];
	public scierie: Tiers[] = [];
	public chauffeurText: string = '';
	public bl: string = '';
	public numfacture: string = '';
	public boisType:     BoisType[] = [];
	public boisQuality:  BoisQuality[] = [];
	public boisSize:     BoisSize[] = [];
	public marquage:     Marquage[] = [];
	public chantier:     Chantier[] = [];
	public volumeInner:  NumberFilter = new NumberFilter();
	public volumeOutter: NumberFilter = new NumberFilter();
	public coef:         NumberFilter = new NumberFilter();
	public numero:       string = '';

	public clear(): void {
		this.date = new DateFilter();
		this.purchase = [];
		this.hasPurchase = [];
		this.scierie = [];
		this.chauffeurText = '';
		this.bl = '';
		this.numfacture = '';
		this.boisType = [];
		this.chantier = [];
		this.boisQuality = [];
		this.boisSize = [];
		this.marquage = [];
		this.chantier = [];
		this.volumeInner = new NumberFilter();
		this.volumeOutter = new NumberFilter();
		this.coef = new NumberFilter();
		this.numero = '';
	}

	public toString(): string {
		return JSON.stringify({
			date: this.date,
			chauffeurText  : this.chauffeurText,
			scierie: this.scierie.map(p => p.id),
			purchase: this.purchase.map(p => p.id),
			hasPurchase: this.hasPurchase,
			bl: this.bl,
			numfacture: this.numfacture,
			boisType    : this.boisType.map(b => b.id),
			boisQuality : this.boisQuality.map(b => b.id),
			boisSize    : this.boisSize.map(b => b.id),
			marquage    : this.marquage.map(m => m.id),
			volumeInner : this.volumeInner.toJSON(),
			volumeOutter: this.volumeOutter.toJSON(),
			chantier    : this.chantier.map(c => c.id),
			coef        : this.coef.toJSON(),
			numero      : this.numero,
		});
	}
}

class CubageLivraisonState extends PaginatorFilterState<CubageLivraisonFilter> {
	public cubageLivraison: CubageLivraison = null;
	public cubageLivraisons: ResultType<CubageLivraison> = null;
	public constructor() {
		super(new CubageLivraisonFilter(), 'cubageLivraisons', 'cubageLivraison', 'id', AscDesc.DESC, 20);
	}
}

class CubageLivraisonStore {

	@XHTTPService(() => CubageLivraison)
	private cubageLivraisonService: CubageLivraisonService;
	private  _store: Store<any>;

	public state: CubageLivraisonState = new CubageLivraisonState();

	public mutations = {
		...PaginatorMutationFilter(() => this._store, 'cubageLivraisons', 'cubageLivraison'),
	};

	public actions = { 
		...PaginatorActionWithFilters<CubageLivraison, CubageLivraisonState>(() => CubageLivraison, 'cubageLivraisons', 'cubageLivraison'),
		...StandardAction<CubageLivraison, CubageLivraisonState>(() => CubageLivraison, 'cubageLivraison'),
	};

	public init(store: Store<any>): this {
		this._store = store;
		return this;
	}
}

const instance = new CubageLivraisonStore();
const storeCubageLivraison = new Vuex.Store(instance);
instance.init(storeCubageLivraison);

const instanceForCubageChantier = new CubageLivraisonStore();
export const storeCubageLivraisonForCubageChantier = new Vuex.Store(instanceForCubageChantier);
instanceForCubageChantier.init(storeCubageLivraisonForCubageChantier);

store.registerModule('cubageLivraison', storeCubageLivraison);
store.registerModule('cubageLivraisonForCubageChantier', storeCubageLivraisonForCubageChantier);
export default storeCubageLivraison;

