














































































	import {Stored} from 'vue-stored-prop-decorator';
	import {Component, Vue} from '@/shared/component';
	import {Emit, Watch} from 'vue-property-decorator';
	import {ResultType} from '@/shared/xhttp';
	import {Tiers, TiersType} from '@/models';

	import CrudTiersFilter from '@/components/Crud/Tiers/Filter.vue';
	import CrudTiersEditDialog from '@/components/Crud/Tiers/EditDialog.vue';
	import CrudTiersList from '@/components/Crud/Tiers/List.vue';
	import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

	import storeTiers, {TiersFilter} from '@/stores/modules/tiers';
	import CrudTiersListPriceM3Dialog from "@/components/Crud/Tiers/ListPrixM3Dialog.vue";

	@Component({
		components: {
			CrudTiersFilter,
			CrudTiersEditDialog,
			CrudTiersList,
			CrudDeleteDialog,
			CrudTiersListPriceM3Dialog,
		}
	})
	export default class TiersListView extends Vue {

		@Stored(() => storeTiers) private tierses!: ResultType<Tiers>;
		@Stored(() => storeTiers) private filters!: TiersFilter;

		private imgPath: string = "";

		private TiersType = TiersType;

		private type: TiersType = null;
		private editTiers: Tiers = null;
		private deletedTiers: Tiers = null;
		private editTiersM3: Tiers = null;
		private storeTiers = storeTiers;

		public created(): void {
			this.routeWatcher();
		}

		@Watch('$route')
		private routeWatcher() {
			this.type = this.$route.meta.type || TiersType.PROPRIETAIRE;
		}

		@Emit()
		private onCreate(): void {
			this.editTiers = new Tiers();
			this.editTiers.type = this.type;
			this.refresh();
		}

		private refresh(): void {
            storeTiers.dispatch('getC', { filters: true });
		}
	}
