import Vue from 'vue';
import Vuex from 'vuex';
import {ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutation,
	StandardAction
} from '@/shared/utils';
import {BoisSize} from '@/models';
import {BoisSizeService} from '@/xhttp';
import store from '@/stores';

Vue.use(Vuex);

export class BoisSizeFilter {
	
	public name: string = '';
	
	public toString(): string {
		return JSON.stringify(this);
	}
}

class BoisSizeState extends PaginatorFilterState<BoisSizeFilter> {
	public boisSize: BoisSize = null;
	public boisSizes: ResultType<BoisSize> = null;
	public boisSizesAll: ResultType<BoisSize> = null;
	public constructor() {
		super(new BoisSizeFilter(), 'boisSizes', 'boisSize');
	}
}

class BoisSizeStore {
	
	@XHTTPService(() => BoisSize)
	private boisSizeService: BoisSizeService;
	
	public state: BoisSizeState = new BoisSizeState();
	
	public mutations = {
		...PaginatorMutation(() => storeBoisSize, 'boisSizes', 'boisSize'),
	};
	
	public actions = {
		...StandardAction<BoisSize, BoisSizeState>(() => BoisSize, 'boisSize'),
		...PaginatorActionWithFilters<BoisSize, BoisSizeState>(() => BoisSize, 'boisSizes', 'boisSize'),
	};
	
}
const instance = new BoisSizeStore();
const storeBoisSize = new Vuex.Store(instance);
store.registerModule('boisSize', storeBoisSize);
export default storeBoisSize;
