






































































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Chauffeur} from '@/models';

import storeChauffeur from '@/stores/modules/chauffeur';

import InputPhone from '@/components/Input/Phone.vue';

@Component({
	components: {
		InputPhone,
	}
})
export default class CrudChauffeurEdit extends AbstractEditVue<Chauffeur> {
	public constructor() {
		super(
			storeChauffeur,
			'Chauffeur modifié',
			'Chauffeur créé',
		);
	}
}
