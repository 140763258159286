












































































import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';

import storePurchase, {PurchaseFilter} from '@/stores/modules/purchase';

import InputDateFilter from '@/components/Input/DateFilter.vue';
import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';
import TrueFalseMultiSelect from '@/components/Input/TrueFalseMultiSelect.vue';

@Component({
	components: {
		InputDateFilter,
		CrudTiersSearch,
		TrueFalseMultiSelect,
	}
})
export default class CrudPurchaseFilter extends Vue {

	@Prop({ type: Boolean, default: false}) reduce;

	@Stored(() => storePurchase) private page!: number;
	@Stored(() => storePurchase) private filters!: PurchaseFilter;

	@WatchObjectProperties('filters', PurchaseFilter)
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		storePurchase.dispatch('getC', { filters: true });
	}

	public mounted() {
		//this.filters = new PurchaseFilter();
	}
}
