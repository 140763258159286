import Vue from 'vue';
import Vuex, {Store} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {NumberFilter} from '@/shared/filter';
import {DateFilter} from '@/shared/filter';
import {Purchase, Tiers} from '@/models';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {BoisQuality, BoisSize, BoisType, Plateforme, CubagePlateformeRestant, Marquage} from '@/models';
import {CubagePlateformeRestantService} from '@/xhttp';
import store from '@/stores';


Vue.use(Vuex);

export class CubagePlateformeRestantFilter {

	public dateDelivery: DateFilter = new DateFilter();
	public boisType:     BoisType[] = [];
	public boisQuality:  BoisQuality[] = [];
	public boisSize:     BoisSize[] = [];
	public plateforme:     Plateforme[] = [];
	public volumeInner:  NumberFilter = new NumberFilter();
	public volumeOutter: NumberFilter = new NumberFilter();
	public coef:         NumberFilter = new NumberFilter();

	public clear(): void {
		this.dateDelivery = new DateFilter();
		this.boisType = [];
		this.plateforme = [];
		this.boisQuality = [];
		this.boisSize = [];
		this.plateforme = [];
		this.volumeInner = new NumberFilter();
		this.volumeOutter = new NumberFilter();
		this.coef = new NumberFilter();
	}

	public toString(): string {
		return JSON.stringify({
			dateDelivery: this.dateDelivery,
			boisType    : this.boisType.map(b => b.id),
			boisQuality : this.boisQuality.map(b => b.id),
			boisSize    : this.boisSize.map(b => b.id),
			volumeInner : this.volumeInner.toJSON(),
			volumeOutter: this.volumeOutter.toJSON(),
			plateforme    : this.plateforme.map(c => c.id),
			coef        : this.coef.toJSON(),
		});
	}
}

class CubagePlateformeRestantState extends PaginatorFilterState<CubagePlateformeRestantFilter> {
	public cubagePlateformeRestant: CubagePlateformeRestant = null;
	public cubagePlateformeRestants: ResultType<CubagePlateformeRestant> = null;
	public constructor() {
		super(new CubagePlateformeRestantFilter(), 'cubagePlateformeRestants', 'cubagePlateformeRestant', 'id', AscDesc.DESC, 20);
	}
}

class CubagePlateformeRestantStore {

	@XHTTPService(() => CubagePlateformeRestant)
	private cubagePlateformeRestantService: CubagePlateformeRestantService;
	private  _store: Store<any>;

	public state: CubagePlateformeRestantState = new CubagePlateformeRestantState();

	public mutations = {
		...PaginatorMutationFilter(() => this._store, 'cubagePlateformeRestants', 'cubagePlateformeRestant'),
	};

	public actions = {
		...PaginatorActionWithFilters<CubagePlateformeRestant, CubagePlateformeRestantState>(() => CubagePlateformeRestant, 'cubagePlateformeRestants', 'cubagePlateformeRestant'),
		...StandardAction<CubagePlateformeRestant, CubagePlateformeRestantState>(() => CubagePlateformeRestant, 'cubagePlateformeRestant'),
	};

	public init(store: Store<any>): this {
		this._store = store;
		return this;
	}
}

const instance = new CubagePlateformeRestantStore();
const storeCubagePlateformeRestant = new Vuex.Store(instance);
instance.init(storeCubagePlateformeRestant);

const instanceForCubagePlateforme = new CubagePlateformeRestantStore();
export const storeCubagePlateformeRestantForCubagePlateforme = new Vuex.Store(instanceForCubagePlateforme);
instanceForCubagePlateforme.init(storeCubagePlateformeRestantForCubagePlateforme);

store.registerModule('cubagePlateformeRestant', storeCubagePlateformeRestant);
store.registerModule('cubagePlateformeRestantForCubagePlateforme', storeCubagePlateformeRestantForCubagePlateforme);
export default storeCubagePlateformeRestant;

