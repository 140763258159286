import Vue from 'vue';
import Vuex, {ActionContext, Store} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {NumberFilter} from '@/shared/filter';
import {DateFilter} from '@/shared/filter';
import {Plateforme, Purchase, Tiers} from '@/models';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {BoisQuality, BoisSize, BoisType, Chantier, CubageLivraisonPlateforme, Marquage} from '@/models';
import {CubageLivraisonPlateformeService} from '@/xhttp';
import store from '@/stores';


Vue.use(Vuex);

export class CubageLivraisonPlateformeFilter {

	public date: DateFilter = new DateFilter();
	public plateforme:   Plateforme[] = [];
	public purchase: Purchase[] = [];
	public hasPurchase: boolean[] = [];
	public scierie: Tiers[] = [];
	public chauffeurText: string = '';
	public bl: string = '';
	public boisType:     BoisType[] = [];
	public boisQuality:  BoisQuality[] = [];
	public boisSize:     BoisSize[] = [];
	public marquage:     Marquage[] = [];
	public chantier:     Chantier[] = [];
	public volumeInner:  NumberFilter = new NumberFilter();
	public volumeOutter: NumberFilter = new NumberFilter();
	public coef:         NumberFilter = new NumberFilter();
	public numero:       string = '';

	public clear(): void {
		this.date = new DateFilter();
		this.plateforme = [];
		this.purchase = [];
		this.hasPurchase = [];
		this.scierie = [];
		this.chauffeurText = '';
		this.bl = '';
		this.boisType = [];
		this.chantier = [];
		this.boisQuality = [];
		this.boisSize = [];
		this.marquage = [];
		this.chantier = [];
		this.volumeInner = new NumberFilter();
		this.volumeOutter = new NumberFilter();
		this.coef = new NumberFilter();
		this.numero = '';
	}

	public toString(): string {
		return JSON.stringify({
			date: this.date,
			plateforme    : this.plateforme.map(b => b.id),
			chauffeurText  : this.chauffeurText,
			scierie: this.scierie.map(p => p.id),
			purchase: this.purchase.map(p => p.id),
			hasPurchase: this.hasPurchase,
			bl: this.bl,
			boisType    : this.boisType.map(b => b.id),
			boisQuality : this.boisQuality.map(b => b.id),
			boisSize    : this.boisSize.map(b => b.id),
			marquage    : this.marquage.map(m => m.id),
			volumeInner : this.volumeInner.toJSON(),
			volumeOutter: this.volumeOutter.toJSON(),
			chantier    : this.chantier.map(c => c.id),
			coef        : this.coef.toJSON(),
			numero      : this.numero,
		});
	}
}

class CubageLivraisonPlateformeState extends PaginatorFilterState<CubageLivraisonPlateformeFilter> {
	public cubageLivraisonPlateforme: CubageLivraisonPlateforme = null;
	public cubageLivraisonPlateformes: ResultType<CubageLivraisonPlateforme> = null;
	public constructor() {
		super(new CubageLivraisonPlateformeFilter(), 'cubageLivraisonPlateformes', 'cubageLivraisonPlateforme', 'id', AscDesc.DESC, 20);
	}
}

class CubageLivraisonPlateformeStore {

	@XHTTPService(() => CubageLivraisonPlateforme)
	private cubageLivraisonPlateformeService: CubageLivraisonPlateformeService;
	private  _store: Store<any>;

	public state: CubageLivraisonPlateformeState = new CubageLivraisonPlateformeState();

	public mutations = {
		...PaginatorMutationFilter(() => this._store, 'cubageLivraisonPlateformes', 'cubageLivraisonPlateforme'),
	};

	public actions = { 
		...PaginatorActionWithFilters<CubageLivraisonPlateforme, CubageLivraisonPlateformeState>(() => CubageLivraisonPlateforme, 'cubageLivraisonPlateformes', 'cubageLivraisonPlateforme'),
		...StandardAction<CubageLivraisonPlateforme, CubageLivraisonPlateformeState>(() => CubageLivraisonPlateforme, 'cubageLivraisonPlateforme'),
		async distributeCubage(context: ActionContext<CubageLivraisonPlateformeState, any>, cubageLivraisonPlateforme: CubageLivraisonPlateforme) {
			let distributeCubage = await instance.cubageLivraisonPlateformeService.distributeCubage(cubageLivraisonPlateforme)
			return distributeCubage;
		},
	};

	public init(store: Store<any>): this {
		this._store = store;
		return this;
	}
}

const instance = new CubageLivraisonPlateformeStore();
const storeCubageLivraisonPlateforme = new Vuex.Store(instance);
instance.init(storeCubageLivraisonPlateforme);

const instanceForCubageChantier = new CubageLivraisonPlateformeStore();
export const storeCubageLivraisonPlateformeForCubageChantier = new Vuex.Store(instanceForCubageChantier);
instanceForCubageChantier.init(storeCubageLivraisonPlateformeForCubageChantier);

store.registerModule('cubageLivraisonPlateforme', storeCubageLivraisonPlateforme);
store.registerModule('cubageLivraisonPlateformeForCubageChantier', storeCubageLivraisonPlateformeForCubageChantier);
export default storeCubageLivraisonPlateforme;

