











































import {Prop} from "vue-property-decorator";
import {ObjectConstructor} from 'gollumts-objecttype';
import {Component, Vue} from '@/shared/component';


@Component()
export default class CrudListReduce extends Vue {
	@Prop({ type: Array                    }) private items!: any[];
	@Prop({ type: Array   , required: true }) private headers!: any[];
	@Prop({ type: Function, required: true }) private showComponent!: ObjectConstructor<Vue>;
	@Prop({ type: String  , required: true }) private routeNameEdit!: String;
	@Prop({ type: String  , required: true }) private routeNameShow!: String;
	@Prop({ type: String  , required: true }) private title!: String;
	@Prop({ type: String  , required: true }) private grantEdit!: String;
	@Prop({ type: String  , required: true }) private grantShow!: String;
	@Prop ({type: Boolean , default: false }) private hasToggle: boolean;

	private show: boolean = false;
	
	public get computedHeaders(): any[] {
		return [].concat(
			[{
				text: 'ID',
				value: 'id',
				align: 'left',
			}],
			this.headers,
			[{
				text: 'Action',
				value: 'action', 
				align: 'right', 
				sortable: false
			}]
		);
	}
	
}
