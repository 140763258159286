




































































































import {Prop, Watch} from "vue-property-decorator";
import {Stored} from "vue-stored-prop-decorator";
import {Component, Vue} from '@/shared/component';
import {Chantier, Chauffeur, CubageChantierRestant, CubagePlateforme} from '@/models';

import storeCubageChantierRestant, {CubageChantierRestantFilter} from "@/stores/modules/cubageChantierRestant";
import storeCubagePlateforme, {CubagePlateformeFilter} from '@/stores/modules/cubagePlateforme';
import storeChantier from "@/stores/modules/chantier";
import storeBoisType from "@/stores/modules/boisType";
import storeBoisQuality from "@/stores/modules/boisQuality";
import storeBoisSize from "@/stores/modules/boisSize";

import Checked from '@/components/Checked.vue';
import CrudList from '@/components/Crud/List.vue';
import TableHead from '@/components/Paginate/TableHead.vue';
import TableCell from '@/components/Paginate/TableCell.vue';
import {NoCache} from "@/shared/decorator";
import storeMarquage from "@/stores/modules/marquage";
import CrudCubagePlateformeEditDialogCreate from "@/components/Crud/CubagePlateforme/EditDialogCreate.vue";
import chauffeur from "@/router/chauffeur";
import {ResultType} from '@/shared/xhttp';

@Component({
	components: {
		CrudList,
		TableHead,
		TableCell,
		Checked,
		CrudCubagePlateformeEditDialogCreate,
	}
})
export default class CrudCubageChantierRestant extends Vue {

	@Prop({type: Boolean, default: true  }) private paginate!: boolean;
	@Prop({type: Boolean, default: false }) private dense!   : boolean;
	@Prop({type: Boolean, default: false }) private totalOfChantier!: boolean;
	@Prop({type: Boolean, default: false }) private showChantier!: boolean;
	@Prop({type: Boolean, default: false }) private forceHideId!: boolean;
	@Prop({type: Boolean, default: false }) private hasAction!: boolean;
	@Prop({type: Chantier, default: null }) private chantier!: Chantier;

	@Stored(() => storeCubageChantierRestant) private filters!: CubageChantierRestantFilter;
	@Stored(() => storeCubageChantierRestant) private cubageChantierRestants!: ResultType<CubageChantierRestant>;

	private storeCubageChantierRestant = storeCubageChantierRestant;
	private storeCubagePlateforme = storeCubagePlateforme;
	private newCubagePlateforme: CubagePlateforme = null;

	private totalLivraisonm3: number = 0;
	private totalLivraisonsteres: number = 0;

	/*public get displayId(): boolean {
		return !this.totalOfPrestation && 
			this.filters.allBois && 
			(
				(this.showPrestation && this.filters.groupPrestation) || !this.showPrestation
			)
		;
	}*/

	private get show(): boolean {
		if (this.totalOfChantier) {
			return !!this.chantier;
		}
		return this.isMounted;
	}

	private get  methodName(): string {
		if (this.totalOfChantier) {
			return 'getCTotalChantiers';
		}
		return 'getC';
	}
	private get listName(): string {
		if (this.totalOfChantier) {
			return 'cubageChantierRestantsChantier';
		}
		return 'list';
	}

	private async mounted(): Promise<void> {
		this.storeCubageChantierRestant.state.limit = 200;
		await Promise.all([
			(async () => storeBoisType   .state.boisTypesAll || await storeBoisType   .dispatch('getAll'))(),
			(async () => storeBoisQuality.state.boisTypesAll || await storeBoisQuality.dispatch('getAll'))(),
			(async () => storeBoisSize   .state.boisTypesAll || await storeBoisSize   .dispatch('getAll'))(),
			(async () => storeMarquage   .state.storeMarquageAll || await storeMarquage   .dispatch('getAll'))(),
		]);
		if (this.chantier) {
			this.filters.chantier = [ this.chantier ];
		} else {
			this.filters.chantier = [];
			this.filters.coupeChantierFinie = true;
		}

		//console.log("mounted");
	}

	@Watch('cubageChantierRestants')
	public calculTotaux(): void {
		this.totalLivraisonm3 = 0;
		this.totalLivraisonsteres = 0;
		for (const cubage of this.cubageChantierRestants.data) {
			if (cubage.boisSize.grume == false)
				this.totalLivraisonsteres = this.totalLivraisonsteres + (cubage.volume / 0.63);
			else
				this.totalLivraisonm3 = this.totalLivraisonm3 + cubage.volume;
		}

	}

	@Watch('chantier')
	private chantierWatcher(): void {
		if (this.chantier) {
			this.filters.chantier = [ this.chantier ];
			//console.log("watch chantier avec");
		} else {
			this.filters.chantier = [];
			this.filters.coupeChantierFinie = true;
			//console.log("watch chantier sans");
		}
	}

	private createCubageLivraisonFromCubageChantier(cubage: CubageChantierRestant): void {
		this.newCubagePlateforme = new CubagePlateforme();
		this.newCubagePlateforme.chantier = this.chantier;
		this.newCubagePlateforme.volumeInner = cubage.volume;
		this.newCubagePlateforme.boisFini = true;
		this.newCubagePlateforme.coef = 0.9;
		this.newCubagePlateforme.coefStere = 0.63;
		this.newCubagePlateforme.volumeOutter = Math.round(this.newCubagePlateforme.volumeInner / this.newCubagePlateforme.coef * 100 ) / 100;
		this.newCubagePlateforme.volumeStere = Math.round(this.newCubagePlateforme.volumeInner / this.newCubagePlateforme.coefStere * 100 ) / 100;
		this.newCubagePlateforme.boisQuality = cubage.boisQuality;
		this.newCubagePlateforme.boisSize = cubage.boisSize;
		this.newCubagePlateforme.boisType = cubage.boisType;
		this.newCubagePlateforme.marquage = cubage.marquage;
		if (!cubage.marquage)
			this.newCubagePlateforme.sansMarquage = true;
		else
			this.newCubagePlateforme.sansMarquage = false;
		
		var oCh = new Chauffeur();
		oCh.id = 5;
		oCh.firstName = "Hervé";
		oCh.lastName = "Chizelle";
		this.newCubagePlateforme.chauffeur = oCh;
	}

	private async OncloseNewLivraisonPlateforme(): Promise<void> {
		await storeCubageChantierRestant.dispatch(this.methodName, { filters: true });
		await storeCubagePlateforme.dispatch('getC', { filters: true });
	}
}
