







































import {Emit} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Role, User} from '@/models';

import CrudUserEditChangePwd from '@/components/Crud/User/EditChangePwd.vue';

@Component({
	components: {
		CrudUserEditChangePwd,
	}
})
export default class CrudUserEditChangePwdDialog extends Vue {
	@InOut({ type: User  , isVModel: true }) private value!: User;

	@Emit()
	private async onSubmit(user: User) : Promise<void>  {
		this.onClose();
	}

	@Emit()
	private onClose() { 
		this.value = null;
	}
}
