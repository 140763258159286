





























import {Prop, Watch} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';
import {Stored} from 'vue-stored-prop-decorator';
import {Tiers} from "@/models";

import storeAbatteur, {AbatteurFilter} from '@/stores/modules/abatteur';

import CrudList from '@/components/Crud/List.vue';
import TableHead from '@/components/Paginate/TableHead.vue';
import TableCell from '@/components/Paginate/TableCell.vue';

@Component({
	components: {
		CrudList,
		TableHead,
		TableCell,
	}
})
export default class CrudAbatteurList extends Vue {

	@Prop({type: Boolean, default: true  }) public paginate!: boolean;
	@Prop({type: Boolean, default: false }) public dense!   : boolean;

	private storeAbatteur = storeAbatteur;
}
