







































	import {Emit} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';

	import {Livraison} from '@/models';

	import CrudLivraisonEdit from '@/components/Crud/Livraison/Edit.vue';

	@Component({
		components: {
			CrudLivraisonEdit,
		}
	})
	export default class CrudLivraisonEditDialog extends Vue {
		@InOut({ type: Livraison  , isVModel: true }) private value!: Livraison;

		@Emit()
		private onSubmit(livraison: Livraison) {
			this.onClose();
		}

		@Emit()
		private onClose() {
			this.value = null;
		}

}
