


























import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';

import storeBoisSize, {BoisSizeFilter} from '@/stores/modules/boisSize';

import TrueFalseMultiSelect from '@/components/Input/TrueFalseMultiSelect.vue';

@Component({
	components: {
		TrueFalseMultiSelect,
	}
})
export default class CrudBoisSizeFilter extends Vue {
	
	@Prop({ type: Boolean, default: false}) reduce;
	
	@Stored(() => storeBoisSize) private page!: number;
	@Stored(() => storeBoisSize) private filters!: BoisSizeFilter;
	
	@WatchObjectProperties('filters', BoisSizeFilter)
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		storeBoisSize.dispatch('getC', { filters: true });
	}
	
}
