




































import {Stored} from 'vue-stored-prop-decorator'
import {Component} from '@/shared/component';
import {ResultType} from '@/shared/xhttp';
import {Role, User} from '@/models';

import storeUser from '@/stores/modules/user';
import storeRole from '@/stores/modules/role';

import InputPhone from '@/components/Input/Phone.vue';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import axios from "axios";

import storeToken from "@/stores/modules/token";
import {Token} from "@/models";

@Component({
	components: {
		InputPhone
	}
})
export default class CrudUserEditChangePwd extends AbstractEditVue<User> {
	
	private showPassword: boolean = false;
	@Stored(() => storeToken) private token: Token;

	@Stored(() => storeRole)
	private roles!: ResultType<Role>;
	
	public constructor() {
		super(
			storeUser,
			'Mot de passe modifié',
			'Mot de passe créé',
		);
	}
	
	public get itemsRoles(): Role[] {
		return this.roles.data
	}
	
	public async mounted(): Promise<void> {
		await storeRole.dispatch('getCNoRefresh');
		await super.mounted();
	}

	public async OnClick(): Promise<void> {
		await axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/users/setpassword/" + this.item.id + "/" + encodeURIComponent(this.item.password) + "?token=" + this.token.id, {});
	}
}
