


































	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';

	import storePrestation from '@/stores/modules/prestation';

	import InputM3PriceList from '@/components/Input/M3PriceList.vue';
    import {Prestation} from "@/models";

	@Component({
		components: {
            InputM3PriceList,
		}
	})
	export default class CrudPrestationEditPriceM3 extends AbstractEditVue<Prestation> {
		public constructor() {
			super(
				storePrestation,
				'Prestation modifié',
				'Prestation créé',
			);
		}

	}
