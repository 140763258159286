







































import {Emit, Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Contact, Tiers} from '@/models';

import CrudContactEdit from '@/components/Crud/Contact/Edit.vue';

@Component({
	components: {
		CrudContactEdit,
	}
})
export default class CrudContactEditDialog extends Vue {
	@InOut({ type: Contact  , isVModel: true }) private value!: Contact;
	@Prop({ type: Tiers, default: null}) tiers: Tiers;

	@Emit()
	private onSubmit(contact: Contact) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}
}
