



























































import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';

import storeChantier, {ChantierFilter} from '@/stores/modules/chantier';

import InputDateFilter from '@/components/Input/DateFilter.vue';
import {DateFilter, DateFilterMode} from '@/shared/filter';
import CrudContactSearch from '@/components/Crud/Contact/Search.vue';
import TrueFalseMultiSelect from '@/components/Input/TrueFalseMultiSelect.vue';
import CrudAbatteurSearch from '@/components/Crud/Abatteur/Search.vue';
import CrudDebardeurSearch from '@/components/Crud/Debardeur/Search.vue';

@Component({
	components: {
		InputDateFilter,
		CrudContactSearch,
		TrueFalseMultiSelect,
		CrudAbatteurSearch,
		CrudDebardeurSearch
	}
})
export default class CrudChantierFilterBilan extends Vue {

	@Prop({ type: Boolean, default: false}) reduce;
	@Stored(() => storeChantier) private page!: number;
	@Stored(() => storeChantier) private filters!: ChantierFilter;

	@WatchObjectProperties('filters', ChantierFilter)
	@Watch('filters.name')
	@Watch('filters.commune')
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		storeChantier.dispatch('getC', { filters: true });
	}

	public mounted() {
		this.filters = new ChantierFilter();
		this.filters.financialClose = [ true ];
		var dt = new Date();
		dt.setDate(dt.getDate() - 90);
		this.filters.dtEnd.start = dt;
		this.filters.dtEnd.end = new Date();
		this.filters.dtEnd.mode = DateFilterMode.RANGE;
	}
}
