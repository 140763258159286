var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-prestation"},[_c('v-card',{staticClass:"general"},[_c('v-card-title',[_c('v-container',{staticClass:"bg-surface-variant"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12","xl":"12"}},[_c('h3',{staticStyle:{"padding":"10px"}},[_vm._v("Cubages estimatifs en attente de chantier")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"2","xl":"2"}},[_c('label',[_vm._v("Total grumes : ")])]),_c('v-col',{attrs:{"cols":"12","lg":"4","xl":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.data.total)+" m3 ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"2","xl":"2"}},[_c('label',[_vm._v("Total billons : ")])]),_c('v-col',{attrs:{"cols":"12","lg":"4","xl":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.data.total_steres)+" stères ")])])],1)],1)],1),(_vm.data)?_c('v-data-table',{staticStyle:{"filter":"drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"},attrs:{"group-by":"id_contrat","headers":_vm.headers,"items":_vm.data.data,"items-per-page":-1,"item-class":_vm.itemRowBackground},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var items = ref.items;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-minus")]):_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{attrs:{"icon":"","color":"see","title":"Afficher le contrat","to":{ name: 'contrat-show', params: { id: items[0].id_contrat } }}},[_c('v-icon',[_vm._v("mdi-eye")])],1),(_vm.isGranted('API_PUT_CUBAGEPRESTATIONESTIMED') && (items[0].docs.length > 0))?_c('v-btn',{attrs:{"icon":"","color":"green","title":"Document"},on:{"click":function($event){$event.stopPropagation();return _vm.onClickDocs(items[0].id_prestation)}}},[_c('v-icon',[_vm._v("mdi-file-document-outline")])],1):_vm._e(),_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v(_vm._s(items[0].contrat_full)+" ")]),_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v(_vm._s(items[0].total_m3)+" m3")]),_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v(_vm._s(items[0].total_steres)+" stères")])],1)]}},{key:"item.volume",fn:function(ref){
var item = ref.item;
return [(item.grume == 1)?_c('span',[_vm._v(_vm._s(item.volume_inner)+" m3")]):_c('span',[_vm._v(_vm._s(item.volume_stere)+" stères")])]}},{key:"item.marquage",fn:function(ref){
var item = ref.item;
return [(item.marquage)?_c('v-chip',{attrs:{"color":item.marquage_color,"dark":""}},[_vm._v(" "+_vm._s(item.marquage)+" ")]):_vm._e()]}}],null,false,2526658534)}):_vm._e()],1),_c('crud-cubage-prestation-estimed-edit-document-dialog',{model:{value:(_vm.editCubagePrestationEstimedDocument),callback:function ($$v) {_vm.editCubagePrestationEstimedDocument=$$v},expression:"editCubagePrestationEstimedDocument"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }