
















































































	import {Prop, Watch} from "vue-property-decorator";
	import {Component, Vue} from '@/shared/component';
	import {Stored} from "vue-stored-prop-decorator";
	import {Livraison, CubageLivraison} from "@/models";
	import {ArrayHelper} from "@/shared/utils";
  	import Checked from '@/components/Checked.vue';
	import {ResultType} from '@/shared/xhttp';
	import CrudCubageLivraisonEditDialogCreate from '@/components/Crud/CubageLivraison/EditDialogCreate.vue';

	@Component({
		components: {
			Checked,
			CrudCubageLivraisonEditDialogCreate,
		}
	})
	export default class CrudCubageLivraisonListForEdit extends Vue {

		@Prop({ type: [ Livraison, Boolean ] , default: false }) public livraison: Livraison;

		private totalLivraisonm3: number = 0;
		private totalLivraisonsteres: number = 0;
		private totalVentes: number = 0;
		private headers2 = [];
		private editCubageLivraison: CubageLivraison = null;

		public mounted() {

		if (this.isGranted("API_GET_PURCHASE")) {
			this.headers2 = [
				{text: 'Chantier', align: 'start', value: 'chantier.displayName'},
				{text: 'Bois', value: 'bois'},
				{text: 'Volume', value: 'volume'},
				{text: 'Prix', value: 'prix'},
				{text: 'Bois livré', value: 'livre'},
				{text: 'Actions', value: 'actions2', sortable: false, filterable: false},
			];
		} else {
			this.headers2 = [
			{text: 'Chantier', align: 'start', value: 'chantier.displayName'},
				{text: 'Bois', value: 'bois'},
				{text: 'Volume', value: 'volume'},
				{text: 'Bois livré', value: 'livre'},
				{text: 'Actions', value: 'actions2', sortable: false, filterable: false},
			];
		}

		this.calculTotaux();
	}

	editCubage(item)
	{

	}

	private async RefreshData()
	{
		this.calculTotaux();
	}


	@Watch('cubageLivraisons')
	public calculTotaux(): void {
			//console.log("filter");
			// on calcul le total
			//console.log(this.prestations);
			this.totalLivraisonm3 = 0;
			this.totalLivraisonsteres = 0;
			this.totalVentes = 0;
			for (const cubage of this.livraison.cubageLivraisons) {
				if (cubage.boisSize.grume == false)
					this.totalLivraisonsteres = this.totalLivraisonsteres + cubage.volumeStere;
				else
					this.totalLivraisonm3 = this.totalLivraisonm3 + cubage.volumeInner;

				this.totalVentes = this.totalVentes + cubage.prixCamion;
			}

		}

	}
