













































































































































































































	import {Prop, Watch} from "vue-property-decorator";
	import {Emit} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
	import {TiersType, BoisQuality, BoisSize, BoisType, Chantier, CubageLivraison, CubageChantier, Marquage} from '@/models';
	import {ObjectHelper} from "@/shared/utils";
	import InputDatePicker from '@/components/Input/DatePicker.vue';

	import storeCubageLivraison from '@/stores/modules/cubageLivraison';
	import storeCubageChantier from '@/stores/modules/cubageChantier';

	import InputBoisType from '@/components/Input/BoisType.vue';
	import InputBoisQuality from '@/components/Input/BoisQuality.vue';
	import InputBoisSize from '@/components/Input/BoisSize.vue';
	import InputMarquage from '@/components/Input/Marquage.vue';
	import CrudChantierSearch from '@/components/Crud/Chantier/Search.vue';
	import boisQuality from '@/router/boisQuality';
	import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';
    import CrudChauffeurSearch from '@/components/Crud/Chauffeur/Search.vue';

	@Component({
		components: {
			InputDatePicker,
			InputBoisType,
			InputBoisQuality,
			InputBoisSize,
			InputMarquage,
			CrudChantierSearch,
			CrudTiersSearch,
			CrudChauffeurSearch,
		}
	})
	export default class CrudCubageLivraisonEdit extends AbstractEditVue<CubageLivraison> {
		
		private TiersType = TiersType;
		private savedisabled: Boolean = true;
		private showMessage: Boolean = false;

		public constructor() {
			super(
				storeCubageLivraison,
				'Cubage modifié',
				'Cubage créé',
			);
		}

		protected async refresh(): Promise<void> {
			await super.refresh();

			this.watcher();
		}

		private onChangeVolumeInner(): void {
			if (this.item.volumeInner) {
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
				if (this.item.coefStere) {
					this.item.volumeStere = Math.round(this.item.volumeInner / this.item.coefStere * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangeVolumeOutter(): void {
			if (this.item.volumeOutter) {
				if (this.item.coef) {
					this.item.volumeInner = Math.round(this.item.volumeOutter * this.item.coef * 100 ) / 100;
				} 
				if (this.item.coefStere) {
					this.item.volumeStere = Math.round(this.item.volumeInner / this.item.coefStere * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangeVolumeStere(): void {
			if (this.item.volumeStere) {
				if (this.item.coefStere) {
					this.item.volumeInner = Math.round(this.item.volumeStere * this.item.coefStere * 100 ) / 100;
				} 
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangeVolumeTonne(): void {
			if (this.item.volumeTonne) {
				if (this.item.coefTonne) {
					this.item.volumeInner = Math.round(this.item.volumeTonne / this.item.coefTonne * 100 ) / 100;
				} 
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangeCoef(): void {
			if (this.item.coef) {
				if (this.item.volumeOutter) {
					this.item.volumeInner = Math.round(this.item.volumeOutter * this.item.coef * 100 ) / 100;
				} 
				if (this.item.coefStere) {
					this.item.volumeStere = Math.round(this.item.volumeInner / this.item.coefStere * 100 ) / 100;
				} 
			}
			this.$forceUpdate(); 
		}

		private onChangeCoefStere(): void {
			if (this.item.coefStere) {
				if (this.item.volumeStere) {
					this.item.volumeInner = Math.round(this.item.volumeStere * this.item.coefStere * 100 ) / 100;
				} 
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
			}
			this.$forceUpdate(); 
		}

		private onChangeCoefTonne(): void {
			if (this.item.coefTonne) {
				if (this.item.volumeTonne) {
					this.item.volumeInner = Math.round(this.item.volumeTonne / this.item.coefTonne * 100 ) / 100;
				} 
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
			}
			this.$forceUpdate(); 
		}

		public watcher(): void {
			if (this.item)
			{
				if	(this.item.prixM3 > 500) {
					this.savedisabled = true
					this.showMessage = true;
				} else {
					this.savedisabled = !this.item.chauffeur || !this.item.scierie;
					this.showMessage = false;
				}
			}
		}

		private getFormattedDate(dateString): String{
			if (dateString == '') return '';
			if (dateString == null) return '';

            const date = new Date(dateString);
                // Then specify how you want your dates to be formatted
            return new Intl.DateTimeFormat('default', { month: 'long', day: 'numeric', year: 'numeric', }).format(date);
		}
}
