



























































	import {Prop, Watch} from "vue-property-decorator";
	import {Component, Vue} from '@/shared/component';
	import {Stored} from "vue-stored-prop-decorator";
	import {Chantier, Prestation} from "@/models";
	import {ArrayHelper} from "@/shared/utils";

	import storeCubagePrestationEstimed, {CubagePrestationEstimedFilter} from '@/stores/modules/cubagePrestationEstimed';

	import CrudList from '@/components/Crud/List.vue';
	import TableHead from '@/components/Paginate/TableHead.vue';
	import TableCell from '@/components/Paginate/TableCell.vue';
	
	@Component({
		components: {
			CrudList,
			TableHead,
			TableCell,
		}
	})
	export default class CrudCubagePrestationEstimedList extends Vue {
		
		@Prop({type: Boolean, default: true  }) public paginate!  : boolean;
        @Prop({type: Boolean, default: false }) public dense!     : boolean;
        @Prop({type: Boolean, default: false }) public hideAction!: boolean;
		@Prop({ type: [ Prestation, Boolean ], default: false }) public prestation: Prestation;
		@Prop({ type: [ Chantier, Boolean ] , default: false }) public chantier: Chantier;

		@Stored(() => storeCubagePrestationEstimed) private filters!: CubagePrestationEstimedFilter;
		
		private storeCubagePrestationEstimed = storeCubagePrestationEstimed;

		public get show(): boolean {
			return this.prestation !== null && this.chantier !== null;
		}
		
		public mounted(): void {
			this.watcher();
		}
		
		@Watch('livraison')
		public watcher(): void {
			if (this.prestation) {
				if (!ArrayHelper.equal(this.filters.prestation, [ this.prestation ])) {
					this.filters.prestation = [ this.prestation ];
				}
			} else {
				this.filters.prestation = [];
			}
		}
	}
