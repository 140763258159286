
















































































import {Prop, Watch} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Tiers, Contact} from '@/models';

import storeContact from '@/stores/modules/contact';

import InputPhone from '@/components/Input/Phone.vue';
import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';

@Component({
	components: {
		InputPhone,
		CrudTiersSearch,
	}
})
export default class CrudContactEdit extends AbstractEditVue<Contact> {
	@Prop({ type: Tiers, default: null}) tiers: Tiers;

	public constructor() {
		super(
			storeContact,
			'Contact modifié',
			'Contact créé',
		);
	}

	@Watch('tiers')
	public contratWatcher(): void {
		if (this.tiers && this.item) {
			this.item.tiers = this.tiers;
		}
	}

	protected async refresh(): Promise<void> {
		await super.refresh();
		if (this.tiers) {
			this.item.tiers = this.tiers;
		}
	}

}
