var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-livraison"},[_c('v-card',{staticClass:"general"},[_c('v-card-title',[_c('v-container',{staticClass:"bg-surface-variant"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4","xl":"4"}},[_c('h3',{staticStyle:{"padding":"10px"}},[_vm._v("Reste à débarder")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"2","xl":"2"}},[_c('label',[_vm._v("Total grumes : ")])]),_c('v-col',{attrs:{"cols":"12","lg":"4","xl":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.data.total)+" m3 ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"2","xl":"2"}},[_c('label',[_vm._v("Total billons : ")])]),_c('v-col',{attrs:{"cols":"12","lg":"4","xl":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.data.total_steres)+" stères ")])])],1)],1)],1),(_vm.data)?_c('v-data-table',{staticStyle:{"filter":"drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"},attrs:{"group-by":"id_chantier","headers":_vm.headers,"items":_vm.data.data,"items-per-page":-1,"item-class":_vm.itemRowBackground},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var items = ref.items;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-minus")]):_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{attrs:{"icon":"","color":"see","title":"Afficher le chantier","to":{ name: 'chantier-show', params: { id: items[0].id_chantier } }}},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v(_vm._s(items[0].chantier_full)+" ")]),_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v(_vm._s(items[0].total_m3)+" m3")]),_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v(_vm._s(items[0].total_steres)+" stères")])],1)]}},{key:"item.volume",fn:function(ref){
var item = ref.item;
return [(item.grume == 1)?_c('span',[_vm._v(_vm._s(item.volume)+" m3")]):_c('span',[_vm._v(_vm._s(item.volume_stere)+" stères")])]}},{key:"item.message_bois",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px","font-weight":"bold","color":"red"}},[_vm._v(_vm._s(item.message_bois))])]}},{key:"item.bois",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.bois_type + ' ' + item.bois_quality + ' ' + item.bois_size)+" "),(item.marquage)?_c('v-chip',{attrs:{"color":item.marquage_color,"dark":""}},[_vm._v(" "+_vm._s(item.marquage)+" ")]):_vm._e()]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.client ? item.client.slice(0, 20)+'...' : ''))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.client))])])]}},{key:"item.date_fin_debardage",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.date_fin_debardage)))])]}},{key:"item.debardeur",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.debardeur ? item.debardeur.slice(0, 20)+'...' : ''))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.debardeur))])])]}},{key:"item.liens",fn:function(ref){
var item = ref.item;
return [(item.chantier_lien1 && item.chantier_lien1!='')?_c('a',{attrs:{"href":item.chantier_lien1,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-crosshairs-gps")])],1):_vm._e(),(item.chantier_lien2 && item.chantier_lien2!='')?_c('a',{attrs:{"href":item.chantier_lien2,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-crosshairs-gps")])],1):_vm._e(),(item.chantier_lien3 && item.chantier_lien3!='')?_c('a',{attrs:{"href":item.chantier_lien3,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-crosshairs-gps")])],1):_vm._e()]}},{key:"item.boisVendu",fn:function(ref){
var item = ref.item;
return [(item.boisVendu == true)?_c('v-icon',{staticStyle:{"color":"green"}},[_vm._v(" mdi-invoice-text-check-outline ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"icon":"","color":"see","title":"Afficher le chantier","to":{ name: 'chantier-show', params: { id: item.id_chantier } }}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1)]}}],null,false,736140518)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }