





























































import {Emit} from 'vue-property-decorator';
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {ResultType} from '@/shared/xhttp';
import {Role, User} from '@/models';

import CrudUserFilter from '@/components/Crud/User/Filter.vue';
import CrudUserEditDialog from '@/components/Crud/User/EditDialog.vue';
import CrudUserList from '@/components/Crud/User/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';
import CrudUserEditChangePwdDialog from "@/components/Crud/User/EditChangePwdDialog.vue";

import storeUser from '@/stores/modules/user';
import storeRole from '@/stores/modules/role';

@Component({
	components: {
		CrudUserFilter,
		CrudUserEditDialog,
		CrudUserList,
		CrudDeleteDialog,
		CrudUserEditChangePwdDialog,
	}
})
export default class UserView extends Vue {

	@Stored(() => storeUser) private users!: ResultType<User>;
	@Stored(() => storeRole) private roles!: ResultType<Role>;

	private editUser: User = null;
	private deletedUser: User = null;
	private storeUser = storeUser;
	private editUserPwd: User = null;

	@Emit()
	private onCreate(): void {
		this.editUser = new User();
	}

	private refresh(): void {
		storeUser.dispatch('getC', { filters: true });
	}
}
