









import {Prop} from 'vue-property-decorator';
import {Component, Vue} from '@/shared/component';

import storeBoisType from '@/stores/modules/boisType';
import InputGenericEntitySelect from './GenericEntitySelect.vue';

@Component({
	components: {
		InputGenericEntitySelect
	}
})
export default class InputBoisType extends Vue {
	
	@Prop ({ type: Function, required: false, default: () => ( () => true ) }) private itemFilter!: () => boolean;
	
	store = storeBoisType;
}
