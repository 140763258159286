
















import {Component, Vue} from '@/shared/component';
import {Prop} from "vue-property-decorator";
import {Form} from "@/shared/form";
import {Chantier, Upload} from "@/models";

import storeUpload from "@/stores/modules/upload";
import storeNotifier, {NotifyInterface} from "@/stores/modules/notifier";

import UploadProgress from '@/components/Input/UploadProgress.vue';

@Component({
	components: {
		UploadProgress
	}
})
export default class CrudDocumentChantierUploaderDocumentChauffeur extends Vue {

	@Prop({ type: Chantier, required: true }) private chantier: Chantier;
	
	private file: File = null;
	private upload: Upload = null;
	private form: Form = new Form();

	private async send(): Promise<void> {
		if (this.file) {
			await this.form.call(async () => {
				this.upload = await storeUpload.dispatch('postDocumentChauffeurChantier', { chantier: this.chantier, file: this.file });
				this.upload = await storeUpload.dispatch('waitFinish', this.upload);
				storeNotifier.dispatch('notify', {
					type: 'success',
					message: 'Document envoyé'
				} as NotifyInterface);
			});
			this.file = null;
		}
	}
}
