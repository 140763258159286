













































import {Prop} from "vue-property-decorator";
import {InOut} from "vue-inout-prop-decorator"
import {Component, Vue} from '@/shared/component';
import {Form} from "@/shared/form";
import {PlateformeCase} from "@/models";

import LoopForm from "@/shared/form/LoopForm.vue";

@Component({
	components: {
		LoopForm,
	}
})
export default class InputPlateformeCaseList extends Vue {
	
	@InOut({ type: Array  , isVModel: true }) private value!: PlateformeCase[];

	private get form(): Form {
		return Form.searchParentForm(this);
	}

	private get formName(): string {
		return Form.searchParentName(this);
	}
	
	private clearForm(): void {
		if (this.form) {
			this.form.clearErrors(this.formName);
			this.form.clearErrors(new RegExp('^priceM3s\\[\\d\\]'));
		}
	}
	
	private add(): void {
		this.clearForm();
		this.value.push(new PlateformeCase());
		this.$emit('input', this.value);
	}
	
	private remove(index: number): void {
		this.value.splice(index, 1);
	}
}
