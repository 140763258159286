



















	import {Emit} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
  import {Chantier} from '@/models';

	import storeChantier from '@/stores/modules/chantier';

  import {Watch} from 'vue-property-decorator';
  import CrudDocumentChantierUploaderDocumentChauffeur from '@/components/Crud/DocumentChantier/UploaderDocumentChauffeur.vue';
  import CrudDocumentChauffeurChantierShow from '@/components/Crud/DocumentChantier/ShowDocumentChauffeur.vue';

	@Component({
		components: {
			CrudDocumentChantierUploaderDocumentChauffeur,
      		CrudDocumentChauffeurChantierShow,
		}
	})
	export default class CrudChantierDocumentChauffeursEdit extends AbstractEditVue<Chantier> {

		public constructor() {
			super(
				storeChantier,
				'Chantier modifié',
				'Chantier créé',
			);
		}

		@Emit()
		private onNext() {
		}

		protected async onSubmit(): Promise<void> {
			await super.onSubmit();
		}

		public async mounted(): Promise<void> {
      		await super.mounted();
		}

	}
