import Vue from 'vue';
import Vuex from 'vuex';
import {ResultType, XHTTPService} from '@/shared/xhttp';
import {NumberFilter} from '@/shared/filter';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {BoisQuality, BoisSize, BoisType, CubageChantier, Marquage, Chantier} from '@/models';
import {CubageChantierService} from '@/xhttp';
import store from '@/stores';


Vue.use(Vuex); 

export class CubageChantierFilter {

	public boisType:     BoisType[] = [];
	public boisQuality:  BoisQuality[] = [];
	public boisSize:     BoisSize[] = [];
	public marquage:     Marquage[] = [];
	public chantier:     Chantier[] = [];
	public volumeInner:  NumberFilter = new NumberFilter();
	public volumeOutter: NumberFilter = new NumberFilter();
	public coef:         NumberFilter = new NumberFilter();
	public numero:       string = '';
	
	public toString(): string {
		return JSON.stringify({
			boisType    : this.boisType.map(b => b.id),
			boisQuality : this.boisQuality.map(b => b.id),
			boisSize    : this.boisSize.map(b => b.id),
			marquage    : this.marquage.map(m => m.id),
			chantier    : this.chantier.map(m => m.id),
			volumeInner : this.volumeInner.toJSON(),
			volumeOutter: this.volumeOutter.toJSON(),
			coef        : this.coef.toJSON(),
			numero      : this.numero,
		});
	}
}

class CubageChantierState extends PaginatorFilterState<CubageChantierFilter> {
	public cubageChantier: CubageChantier = null;
	public cubageChantiers: ResultType<CubageChantier> = null;
	public constructor() {
		super(new CubageChantierFilter(), 'cubageChantiers', 'cubageChantier');
	}
}

class CubageChantierStore {
	
	@XHTTPService(() => CubageChantier)
	private cubageChantierService: CubageChantierService;
	
	public state: CubageChantierState = new CubageChantierState();
	
	public mutations = {
		...PaginatorMutationFilter(() => storeCubageChantier, 'cubageChantiers', 'cubageChantier'),
	};
	
	public actions = {
		...PaginatorActionWithFilters<CubageChantier, CubageChantierState>(() => CubageChantier, 'cubageChantiers', 'cubageChantier'),
		...StandardAction<CubageChantier, CubageChantierState>(() => CubageChantier, 'cubageChantier'),
	};
	
}

const instance = new CubageChantierStore();
const storeCubageChantier = new Vuex.Store(instance);
store.registerModule('cubageChantier', storeCubageChantier);
export default storeCubageChantier;
