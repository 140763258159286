































































































	import {Emit} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';
	import {LivraisonPlateformePourChauffeur} from '@/models';
	import CrudLivraisonPlateformePourChauffeurEditDialog from '@/components/Crud/LivraisonPlateformePourChauffeur/EditDialog.vue';
	import axios from "axios";
	import {Token} from "@/models";
	import storeToken from "@/stores/modules/token";
	import {Stored} from "vue-stored-prop-decorator";
	import storeLivraisonPlateformePourChauffeur from '@/stores/modules/livraisonPlateformePourChauffeur';
	import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

	@Component({
		components: {
			CrudLivraisonPlateformePourChauffeurEditDialog, 
			CrudDeleteDialog,
		}
	})
	export default class CrudLivraisonPlateformePourChauffeurEditDialogList extends Vue {
		@InOut({ isVModel: true }) private value!: boolean;
		@InOut({ }) public result!: boolean;
		@Stored(() => storeToken) private token: Token;

		private newLivraison: LivraisonPlateformePourChauffeur = null;
		private data = '';
		private editLivraison: LivraisonPlateformePourChauffeur = null;
		private deletedLivraison: LivraisonPlateformePourChauffeur = null;
		private storeLivraisonPlateformePourChauffeur = storeLivraisonPlateformePourChauffeur;

		public async mounted(): Promise<void> {
			this.result = false;	
			console.log("Mounted");
		}

		onAddLivraison()
		{
			this.newLivraison = new LivraisonPlateformePourChauffeur();

		}

		public async RefreshData(): Promise<void> 
		{
			await axios.get(process.env.VUE_APP_BACK_BASE_URL + "/api/livraison-plateforme-pour-chauffeur/all?token=" + this.token.id, {}).then(async response => {
				this.data = await response.data;
				console.log(this.data);
			});
		}

		public async EditLivraison(value): Promise<void> 
		{
			await this.storeLivraisonPlateformePourChauffeur.dispatch('get', value.id);
			this.editLivraison = (this.storeLivraisonPlateformePourChauffeur.state).current;
		}
		
		public async DeleteLivraison(value): Promise<void> 
		{
			await this.storeLivraisonPlateformePourChauffeur.dispatch('get', value.id);
			this.deletedLivraison = (this.storeLivraisonPlateformePourChauffeur.state).current;
		}

		@Emit()
		private onSubmit(livraisonPlateformePourChauffeur: LivraisonPlateformePourChauffeur) {
			this.result = true;			
			this.value = false;
		}

		@Emit()
		private onClose() {
			this.result = false;			
			this.value = false;
		}

}
