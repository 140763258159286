import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {XHTTPService} from '@/shared/xhttp';
import {DocumentCubagePourClient} from '@/models';
import {DocumentCubagePourClientService} from '@/xhttp';
import store from '@/stores';
//import cubage from "@/router/cubage";
import storeCubage from "@/stores/modules/cubageChantier";

Vue.use(Vuex);

class DocumentCubagePourClientState {
}

class DocumentCubagePourClientStore {

	@XHTTPService(() => DocumentCubagePourClient)
	private documentService: DocumentCubagePourClientService;

	public state: DocumentCubagePourClientState = new DocumentCubagePourClientState();

	public mutations = {
	};

	public actions = {
		async delete(context: ActionContext<DocumentCubagePourClientState, any>, document: DocumentCubagePourClient): Promise<DocumentCubagePourClient> {
			try {
				document.cubage.removeDocuments(document);
				return await instance.documentService.delete('/{id}', document);
			} catch (e) {
				storeCubage.dispatch('get', document.cubage.id);
				throw e;
			}
		}
	};

}
const instance = new DocumentCubagePourClientStore();
const storeDocumentCubagePourClient = new Vuex.Store(instance);
store.registerModule('document', storeDocumentCubagePourClient);
export default storeDocumentCubagePourClient;
