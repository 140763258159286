






















import {Prop} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';

import storePlateforme from '@/stores/modules/plateforme';

import CrudList from '@/components/Crud/List.vue';
import TableHead from '@/components/Paginate/TableHead.vue';
import TableCell from '@/components/Paginate/TableCell.vue';
import Checked from '@/components/Checked.vue';

@Component({
	components: {
		CrudList,
		TableHead,
		TableCell,
		Checked,
	}
})
export default class CrudPlateformeList extends Vue {

	@Prop({type: Boolean, default: true  }) private paginate!: boolean;
	@Prop({type: Boolean, default: false }) private dense!   : boolean;

	private storePlateforme = storePlateforme;
}
