













































































































	import {Prop} from "vue-property-decorator";
	import {InOut} from "vue-inout-prop-decorator"
	import {Component, Vue} from '@/shared/component';
	import {Form} from "@/shared/form";
	import {Parcelle} from "@/models";

	import LoopForm from "@/shared/form/LoopForm.vue";
	
	@Component({
		components: {
			LoopForm,
		}
	})
	export default class InputParcellesList extends Vue {
		
		@InOut({ type: Array  , isVModel: true }) private value!: Parcelle[];
		@Prop ({ type: Boolean, default: false }) private requiredDtDepot!: boolean;

		private get form(): Form {
			return Form.searchParentForm(this);
		}

		private get formName(): string {
			return Form.searchParentName(this);
		}
		
		private clearForm(): void {
			if (this.form) {
				this.form.clearErrors(this.formName);
				this.form.clearErrors(new RegExp('^parcelles\\[\\d\\]'));
			}
		}
		
		private _NewP : Parcelle;

		private add(): void {
			this.clearForm();
			this._NewP = new Parcelle();
			if (this.value.length)
			{
				this._NewP.numeroSection = this.value[this.value.length - 1].numeroSection;
				this._NewP.zipCode = this.value[this.value.length - 1].zipCode;
				this._NewP.town = this.value[this.value.length - 1].town;
				this._NewP.lieuxDit = this.value[this.value.length - 1].lieuxDit;
			}
			this.value.push(this._NewP);
			this.$emit('input', this.value);
		}
		
		private remove(index: number): void {
			this.value.splice(index, 1);
		}

		private eventOnCommune(): void {
				this.$emit('newParcelle', this.value);
				this.$emit('input', this.value); 
		}
}
