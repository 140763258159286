









































































































































	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
    import {CubagePrestationEstimed} from "@/models";

	import storeCubagePrestationEstimed from '@/stores/modules/cubagePrestationEstimed';

	import InputBoisType from '@/components/Input/BoisType.vue';
	import InputBoisQuality from '@/components/Input/BoisQuality.vue';
	import InputBoisSize from '@/components/Input/BoisSize.vue';
	import InputMarquage from '@/components/Input/Marquage.vue';
	import {ObjectHelper} from "@/shared/utils";

	@Component({
		components: {
			InputBoisType,
			InputBoisQuality,
			InputBoisSize,
			InputMarquage,
		}
	})
	export default class CrudCubagePrestationEstimedEdit extends AbstractEditVue<CubagePrestationEstimed> {
		public constructor() {
			super(
				storeCubagePrestationEstimed,
				'Cubage modifié',
				'Cubage créé',
			);
		}

		protected async refresh(): Promise<void> {
			this.item = ObjectHelper.clone(this.value);
		}

		private onChangeVolumeInner(): void {
			if (this.item.volumeInner) {
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
				if (this.item.coefStere) {
					this.item.volumeStere = Math.round(this.item.volumeInner / this.item.coefStere * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangeVolumeOutter(): void {
			if (this.item.volumeOutter) {
				if (this.item.coef) {
					this.item.volumeInner = Math.round(this.item.volumeOutter * this.item.coef * 100 ) / 100;
				} 
				if (this.item.coefStere) {
					this.item.volumeStere = Math.round(this.item.volumeInner / this.item.coefStere * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangeVolumeStere(): void {
			if (this.item.volumeStere) {
				if (this.item.coefStere) {
					this.item.volumeInner = Math.round(this.item.volumeStere * this.item.coefStere * 100 ) / 100;
				} 
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangeCoef(): void {
			if (this.item.coef) {
				if (this.item.volumeOutter) {
					this.item.volumeInner = Math.round(this.item.volumeOutter * this.item.coef * 100 ) / 100;
				} 
				if (this.item.coefStere) {
					this.item.volumeStere = Math.round(this.item.volumeInner / this.item.coefStere * 100 ) / 100;
				} 
			}
			this.$forceUpdate(); 
		}

		private onChangeCoefStere(): void {
			if (this.item.coefStere) {
				if (this.item.volumeStere) {
					this.item.volumeInner = Math.round(this.item.volumeStere * this.item.coefStere * 100 ) / 100;
				} 
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
			}
			this.$forceUpdate(); 
		}
	}
