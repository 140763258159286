















































































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {Abatteur} from '@/models';

import CrudAbatteurFilter from '@/components/Crud/Abatteur/Filter.vue';
import CrudAbatteurShowDialog from '@/components/Crud/Abatteur/ShowDialog.vue';
import CrudAbatteurEditDialog from '@/components/Crud/Abatteur/EditDialog.vue';
import CrudAbatteurList from '@/components/Crud/Abatteur/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storeAbatteur, {AbatteurFilter} from '@/stores/modules/abatteur';

@Component({
	components: {
		CrudAbatteurFilter,
		CrudAbatteurShowDialog,
		CrudAbatteurEditDialog,
		CrudAbatteurList,
		CrudDeleteDialog,
	}
})
export default class AbatteurView extends Vue {
	
	@Stored(() => storeAbatteur)
	private abatteurs!: ResultType<Abatteur>;
	
	@Stored(() => storeAbatteur)
	private filters!: AbatteurFilter;

	private showAbatteur: Abatteur = null;
	private editAbatteur: Abatteur = null;
	private deletedAbatteur: Abatteur = null;
	private storeAbatteur = storeAbatteur;

	@Emit()
	private onCreate(): void {
		this.editAbatteur = new Abatteur();
	}

	public mounted(): void {
		console.log("Mounted abatteur");
		this.filters.clear();
	}

	private refresh(): void {
		storeAbatteur.dispatch('getC', { filters: true });
	}
}
