import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {XHTTPService} from '@/shared/xhttp';
import {DocumentPrestation} from '@/models';
import {DocumentPrestationService} from '@/xhttp';
import store from '@/stores';
import prestation from "@/router/prestation";
import storePrestation from "@/stores/modules/prestation";

Vue.use(Vuex);

class DocumentPrestationState {
}

class DocumentPrestationStore {

	@XHTTPService(() => DocumentPrestation)
	private documentService: DocumentPrestationService;

	public state: DocumentPrestationState = new DocumentPrestationState();

	public mutations = {
	};

	public actions = {
		async delete(context: ActionContext<DocumentPrestationState, any>, document: DocumentPrestation): Promise<DocumentPrestation> {
			try {
				document.prestation.removeDocuments(document);
				return await instance.documentService.delete('/{id}', document);
			} catch (e) {
				storePrestation.dispatch('get', document.prestation.id);
				throw e;
			}
		}
	};

}
const instance = new DocumentPrestationStore();
const storeDocumentPrestation = new Vuex.Store(instance);
store.registerModule('document', storeDocumentPrestation);
export default storeDocumentPrestation;
