import Vue from 'vue';
import Vuex from 'vuex';
import {ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutation,
	StandardAction
} from '@/shared/utils';
import {BoisType} from '@/models';
import {BoisTypeService} from '@/xhttp';
import store from '@/stores';

Vue.use(Vuex);

export class BoisTypeFilter {
	
	public name: string = '';
	
	public toString(): string {
		return JSON.stringify(this);
	}
}

class BoisTypeState extends PaginatorFilterState<BoisTypeFilter> {
	public boisType: BoisType = null;
	public boisTypes: ResultType<BoisType> = null;
	public boisTypesAll: ResultType<BoisType> = null;
	public constructor() {
		super(new BoisTypeFilter(), 'boisTypes', 'boisType');
	}
}

class BoisTypeStore {
	
	@XHTTPService(() => BoisType)
	private boisTypeService: BoisTypeService;
	
	public state: BoisTypeState = new BoisTypeState();
	
	public mutations = {
		...PaginatorMutation(() => storeBoisType, 'boisTypes', 'boisType'),
	};
	
	public actions = {
		...StandardAction<BoisType, BoisTypeState>(() => BoisType, 'boisType'),
		...PaginatorActionWithFilters<BoisType, BoisTypeState>(() => BoisType, 'boisTypes', 'boisType'),
	};
	
}
const instance = new BoisTypeStore();
const storeBoisType = new Vuex.Store(instance);
store.registerModule('boisType', storeBoisType);
export default storeBoisType;
