





































import {Prop, Watch} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';

import {InOut} from "vue-inout-prop-decorator/index";
import CrudDeleteDialog from "@/components/Crud/DeleteDialog.vue";
import {Stored} from "vue-stored-prop-decorator/index";
import storeCubageLivraisonPlateforme from "@/stores/modules/cubageLivraisonPlateforme";
import {CubageLivraison, CubageLivraisonPlateforme} from "@/models";

@Component({
	components: {
		CrudDeleteDialog,
	}
})
export default class CrudLivraisonListLivraison extends Vue {

	@Stored(() => storeCubageLivraisonPlateforme)
	private cubageLivraisonPlateforme: CubageLivraisonPlateforme;
	@InOut({ type: CubageLivraison  , isVModel: true }) private value!: CubageLivraison;

	public constructor() {
		super();
	}

	private headers = [
		{
			text: 'Chantier',
			value: 'chantier',
		},
		{ text: 'Vol. sous.', value: 'volume' },
		{ text: 'Client', value: 'chantier.client' },
		{ text: 'Commune', value: 'chantier.commune' },
	];
}
