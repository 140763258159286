








































	import {Emit, Prop} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';
    import {Contrat, Parcelle, Prestation} from "@/models";

	import CrudParcelleEdit from '@/components/Crud/Parcelle/Edit.vue';

	@Component({
		components: {
			CrudParcelleEdit,
		}
	})
	export default class CrudParcelleEditDialog extends Vue {
		@InOut({ type: Parcelle  , isVModel: true }) private value!: Parcelle;
		@Prop({ type: Prestation, default: null }) prestation: Prestation;

		@Emit()
		private onSubmit(parcelle: Parcelle) {
			this.onClose();
		}

		@Emit()
		private onClose() {
			this.value = null;
		}
	}
