





















































































































































































































































	import {Prop, Watch} from "vue-property-decorator";
	import {Emit} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
	import {TiersType, BoisQuality, BoisSize, BoisType, Chantier, CubagePlateforme, CubageChantier, Marquage} from '@/models';
	import {ObjectHelper} from "@/shared/utils";
	import InputDatePicker from '@/components/Input/DatePicker.vue';
	import {Stored} from "vue-stored-prop-decorator";

	import storeCubagePlateforme from '@/stores/modules/cubagePlateforme';
	import storeCubageChantier from '@/stores/modules/cubageChantier';

	import InputBoisType from '@/components/Input/BoisType.vue';
	import InputBoisQuality from '@/components/Input/BoisQuality.vue';
	import InputBoisSize from '@/components/Input/BoisSize.vue';
	import InputMarquage from '@/components/Input/Marquage.vue';
	import CrudChantierSearch from '@/components/Crud/Chantier/Search.vue';
	import boisQuality from '@/router/boisQuality';
	import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';
    import CrudChauffeurSearch from '@/components/Crud/Chauffeur/Search.vue';
	import storePlateforme from "@/stores/modules/plateforme";
	import storePlateformeCase,  {PlateformeCaseFilter} from "@/stores/modules/plateformeCase";
	import store from "@/stores";

	@Component({
		components: {
			InputDatePicker,
			InputBoisType,
			InputBoisQuality,
			InputBoisSize,
			InputMarquage,
			CrudChantierSearch,
			CrudTiersSearch,
			CrudChauffeurSearch,
		}
	})
	export default class CrudCubagePlateformeEditCreate extends AbstractEditVue<CubagePlateforme> {
		
		//private chantier: Chantier = null;
		private TypeBoisVisible: Boolean = false;
		private TiersType = TiersType;

		private storePlateforme = storePlateforme;
		private storePlateformeCase = storePlateformeCase;

		private step: number = 1;
		private nextdisabled: Boolean = true;

		@Stored(() => storePlateformeCase) private filters!: PlateformeCaseFilter;

		//private SansMarquage: Boolean = false;

		public constructor() {
			super(
				storeCubagePlateforme,
				'Cubage modifié',
				'Cubage créé',
			);
		}

		protected async refresh(): Promise<void> {
			await super.refresh();

			this.watcher();
		}

		public async mounted(): Promise<void> {
			await storePlateforme.dispatch('getAll');
			await super.mounted();
			this.checkInputs();
		}

		public async PlateformeChanged()
		{
			/*console.log("PlateformeChanged");
			//this.filters.plateforme = [ this.item.plateforme ]; 
			console.log(this.item.plateforme);
			await storePlateformeCase.dispatch('getC', {filter: false});
			console.log(storePlateformeCase.state.plateformeCases);*/

			this.checkInputs();
		}

		public checkInputs() {
			if (this.item.plateforme && this.item.chauffeur) { this.nextdisabled = false }
			else { this.nextdisabled = true }
		}

		public watcher(): void {
		}

		@Emit()
		private onNext() {
			this.step = this.step + 1;
		}

		@Emit()
		private onPrevious() {
			this.step = this.step - 1;
		}

		/*protected async refresh(): Promise<void> {
			this.item = ObjectHelper.clone(this.value);

			//console.log("rEFRESH CUBAGE");
		}*/

		private onChangeVolumeInner(): void {
			if (this.item.volumeInner) {
				console.log("COEFE");
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
				
				if (this.item.coefStere) {
					this.item.volumeStere = Math.round(this.item.volumeInner / this.item.coefStere * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangeVolumeOutter(): void {
			if (this.item.volumeOutter) {
				if (this.item.coef) {
					this.item.volumeInner = Math.round(this.item.volumeOutter * this.item.coef * 100 ) / 100;
				} 
				if (this.item.coefStere) {
					this.item.volumeStere = Math.round(this.item.volumeInner / this.item.coefStere * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangeVolumeStere(): void {
			if (this.item.volumeStere) {
				if (this.item.coefStere) {
					this.item.volumeInner = Math.round(this.item.volumeStere * this.item.coefStere * 100 ) / 100;
				} 
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangeCoef(): void {
			if (this.item.coef) {
				if (this.item.volumeOutter) {
					this.item.volumeInner = Math.round(this.item.volumeOutter * this.item.coef * 100 ) / 100;
				} 
				if (this.item.coefStere) {
					this.item.volumeStere = Math.round(this.item.volumeInner / this.item.coefStere * 100 ) / 100;
				} 
			}
			this.$forceUpdate(); 
		}

		private onChangeCoefStere(): void {
			if (this.item.coefStere) {
				if (this.item.volumeStere) {
					this.item.volumeInner = Math.round(this.item.volumeStere * this.item.coefStere * 100 ) / 100;
				} 
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
			}
			this.$forceUpdate(); 
		}

		private onMarquageChange(): void {
			var boisVisible = true;
			if (this.item.chantier != null)
			{
				this.item.chantier.cubageChantiers.forEach((value, index) => {
					if ((value.marquage) && (value.marquage.name === this.item.marquage.name))
					{
						boisVisible = false;
						this.item.boisType = value.boisType;
						this.item.boisQuality = value.boisQuality;
						this.item.boisSize = value.boisSize;
					}
				});
			}

			this.TypeBoisVisible = boisVisible;
		}

	}
