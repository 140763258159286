









import {Prop} from 'vue-property-decorator';
import {Component, Vue} from '@/shared/component';

import storeBoisQuality from '@/stores/modules/boisQuality';
import InputGenericEntitySelect from './GenericEntitySelect.vue';

@Component({
	components: {
		InputGenericEntitySelect
	}
})
export default class InputBoisQuality extends Vue {

	@Prop ({ type: Function, required: false, default: () => ( () => true ) }) private itemFilter!: () => boolean;
	
	store = storeBoisQuality;
}
