import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {ObjectString} from 'gollumts-objecttype';
import {XHTTPService} from '@/shared/xhttp';
import {Uuid} from "@/shared/utils";
import {CubagePrestationEstimed, DocumentCubagePourChauffeur, DocumentCubagePourClient,CubageChantier, DocumentPrestationCubage, CubageLivraison, DocumentCubage, DocumentCubageLivraison, Prestation, DocumentPrestation, Chantier, DocumentChantier, Contrat, Document, Upload} from '@/models';
import {UploadService} from '@/xhttp';

import store from '@/stores';
import storeLoader from "@/stores/modules/loader";
import storeContrat from "@/stores/modules/contrat";
import storeChantier from "@/stores/modules/chantier";
import storePrestation from "@/stores/modules/prestation";
import storeCubageChantier from "@/stores/modules/cubageChantier";
import storeCubageLivraison from "@/stores/modules/cubageLivraison";
import storeCubagePrestationEstimed from "@/stores/modules/cubagePrestationEstimed";

Vue.use(Vuex);


const getChunkFile = (file: File, offset: number, length): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () =>  resolve(<string>reader.result);
        reader.onerror = () =>  reject(reader.error);
        reader.readAsBinaryString(file.slice(offset, offset + length));
    });
};

const fileToUpload = (
    file: File,
    calbbackSend: (upload: Upload) => Promise<Upload>,
    calbbackEnd: (upload: Upload) => void = (upload: Upload) => {},
    fileName: string = null
): Upload => {

    let upload = new Upload();
    upload.uuid = Uuid.generate();
    upload.originalFileName = file.name;
    if (fileName) {
        upload.media.fileName = fileName;
    }

    // Call all chunck
    const finish = (async (): Promise<Upload> => {
        try {
            storeLoader.commit('push');

            const chunkSize = 1024 * 300; // 300ko (base 64 add 33% of size)
            let offset = 0;

            storeUpload.commit('setProgress', { upload, progress: 0 });
            let i = 0;
            const totalChunck = Math.ceil(file.size / chunkSize);
            while (!upload.endOfFile) {
                upload.endOfFile = chunkSize > file.size - offset;

                upload.data = btoa(
                    await getChunkFile(file, offset, chunkSize)
                );
                offset += chunkSize;
                upload = await calbbackSend(upload);
                i++;
                storeUpload.commit('setProgress', { upload, progress: i / totalChunck });
            }
            calbbackEnd(upload);

            setTimeout(() => {
                storeUpload.commit('clearProgress', upload);
            }, 2000);
            storeLoader.commit('pop');

        } catch (e) {
            setTimeout(() => {
                storeUpload.commit('clearProgress', upload);
            }, 2000);
            storeLoader.commit('pop');
            throw e;
        }
        return upload;
    })();

    storeUpload.commit('setFinish', { upload, finish });

    // Return result first chunck
    return upload;
};

class UploadState {
    finishs: ObjectString<Promise<Upload>> = {};
    progresses: ObjectString<number> = {};
}

class UploadStore {

    @XHTTPService(() => Upload)
    private uploadService: UploadService;

    public state: UploadState = new UploadState();

    public mutations = {
        setFinish(state: UploadState, { upload, finish }: { upload: Upload, finish: Promise<Upload> }) {
            const finishs = {};
            finishs[upload.uuid] = finish;
            state.finishs = { ...state.finishs, ...finishs };
        },

        setProgress(state: UploadState, { upload, progress }: { upload: Upload, progress: number }) {
            const progresses = {};
            progresses[upload.uuid] = progress;
            state.progresses = { ...state.progresses, ...progresses };
        },

        clearProgress(state: UploadState, upload: Upload) {
            const progresses = { ...state.progresses };
            delete(progresses[upload.uuid]);
            state.progresses = progresses;
        },
    };

    public actions = {
        async postDocument(context: ActionContext<UploadState, any>, {file, contrat}: { file: File, contrat: Contrat}): Promise<Upload> {
            return fileToUpload(
                file,
                upload => instance.uploadService.postDocument(contrat, upload),
                upload => {
                    const document = new Document();
                    document.media = upload.media;
                    contrat.addDocuments(document);
                    storeContrat.dispatch('get', contrat.id);
                }
            );
        },

        async postDocumentChantier(context: ActionContext<UploadState, any>, {file, chantier}: { file: File, chantier: Chantier}): Promise<Upload> {
            return fileToUpload(
                file,
                upload => instance.uploadService.postDocumentChantier(chantier, upload),
                upload => {
                    const document = new DocumentChantier();
                    document.media = upload.media;
                    chantier.addDocuments(document);
                    storeChantier.dispatch('get', chantier.id);
                }
            );
        },

        async postDocumentChantierPhoto(context: ActionContext<UploadState, any>, {file, chantier}: { file: File, chantier: Chantier}): Promise<Upload> {
            return fileToUpload(
                file,
                upload => instance.uploadService.postDocumentChantierPhoto(chantier, upload),
                upload => {
                    const document = new DocumentChantier();
                    document.media = upload.media;
                    chantier.addPhotos(document);
                    storeChantier.dispatch('get', chantier.id);
                }
            );
        },

        async postDocumentChauffeurChantier(context: ActionContext<UploadState, any>, {file, chantier}: { file: File, chantier: Chantier}): Promise<Upload> {
            return fileToUpload(
                file,
                upload => instance.uploadService.postDocumentChauffeurChantier(chantier, upload),
                upload => {
                    const document = new DocumentChantier();
                    document.media = upload.media;
                    chantier.addDocumentChauffeurs(document);
                    storeChantier.dispatch('get', chantier.id);
                }
            );
        },

        async postDocumentPrestation(context: ActionContext<UploadState, any>, {file, prestation}: { file: File, prestation: Prestation}): Promise<Upload> {
            return fileToUpload(
                file,
                upload => instance.uploadService.postDocumentPrestation(prestation, upload),
                upload => {
                    const document = new DocumentPrestation();
                    document.media = upload.media;
                    prestation.addDocuments(document);
                    storePrestation.dispatch('get', prestation.id);
                }
            );
        },

        async postDocumentPrestationCubage(context: ActionContext<UploadState, any>, {file, prestation}: { file: File, prestation: Prestation}): Promise<Upload> {
            return fileToUpload(
                file,
                upload => instance.uploadService.postDocumentPrestationCubage(prestation, upload),
                upload => {
                    const document = new DocumentPrestationCubage();
                    document.media = upload.media;
                    prestation.addDocumentsCubage(document);
                    storePrestation.dispatch('get', prestation.id);
                }
            );
        },

        async postDocumentCubage(context: ActionContext<UploadState, any>, {file, cubage}: { file: File, cubage: CubageChantier}): Promise<Upload> {
            return fileToUpload(
                file,
                upload => instance.uploadService.postDocumentCubage(cubage, upload),
                upload => {
                    const document = new DocumentCubage();
                    document.media = upload.media;
                    cubage.addDocuments(document);
                    storeCubageChantier.dispatch('get', cubage.id);
                }
            );
        },

        async postDocumentCubagePourChauffeur(context: ActionContext<UploadState, any>, {file, cubage}: { file: File, cubage: CubageChantier}): Promise<Upload> {
            return fileToUpload(
                file,
                upload => instance.uploadService.postDocumentCubagePourChauffeur(cubage, upload),
                upload => {
                    const document = new DocumentCubagePourChauffeur();
                    document.media = upload.media;
                    cubage.addDocumentsPourChauffeur(document);
                    storeCubageChantier.dispatch('get', cubage.id);
                }
            );
        },

        async postDocumentCubageLivraison(context: ActionContext<UploadState, any>, {file, cubage}: { file: File, cubage: CubageLivraison}): Promise<Upload> {
            return fileToUpload(
                file,
                upload => instance.uploadService.postDocumentCubageLivraison(cubage, upload),
                upload => {
                    const document = new DocumentCubageLivraison();
                    document.media = upload.media;
                    cubage.addDocuments(document);
                    storeCubageLivraison.dispatch('get', cubage.id);
                }
            );
        },

        async postDocumentCubagePourClient(context: ActionContext<UploadState, any>, {file, cubage}: { file: File, cubage: CubagePrestationEstimed}): Promise<Upload> {
            return fileToUpload(
                file,
                upload => instance.uploadService.postDocumentCubagePourClient(cubage, upload),
                upload => {
                    const document = new DocumentCubagePourClient();
                    document.media = upload.media;
                    cubage.addDocuments(document);
                    storeCubagePrestationEstimed.dispatch('get', cubage.id);
                }
            );
        },

        async waitFinish(context: ActionContext<UploadState, any>, upload: Upload): Promise<Upload> {
            return await context.state.finishs[upload.uuid];
        }
    };

}
const instance = new UploadStore();
const storeUpload = new Vuex.Store(instance);
store.registerModule('upload', storeUpload);
export default storeUpload;
