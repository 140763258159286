


































	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';

	import storeTiers from '@/stores/modules/tiers';

	import InputTiersM3PriceList from '@/components/Input/TiersM3PriceList.vue';
    import {Tiers} from "@/models";

	@Component({
		components: {
            InputTiersM3PriceList,
		}
	})
	export default class CrudTiersEditPriceM3 extends AbstractEditVue<Tiers> {
		public constructor() {
			super(
				storeTiers,
				'Tiers modifié',
				'Tiers créé',
			);
		}

	}
