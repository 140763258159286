








































import {Emit, Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Contrat, Prestation} from '@/models';

import CrudPrestationEditAlerte from '@/components/Crud/Prestation/EditAlerte.vue';

@Component({
	components: {
		CrudPrestationEditAlerte,
	}
})
export default class CrudPrestationEditDialogAlerte extends Vue {
	@InOut({ type: Prestation  , isVModel: true }) private value!: Prestation;
	@Prop({ type: Contrat, default: null}) contrat: Contrat;

	@Emit()
	private onSubmit(prestation: Prestation) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		if (this)
			this.value = null;
	}
}
