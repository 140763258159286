








































	import {Emit, Prop} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';

	import {Plateforme} from '@/models';

	import CrudPlateformeEditCase from '@/components/Crud/Plateforme/EditCases.vue';

	@Component({
		components: {
            CrudPlateformeEditCase, 
		}
	})
	export default class CrudPlateformeEditDialogCases extends Vue {
		@InOut({ type: Plateforme  , isVModel: true }) private value!: Plateforme;

		@Emit()
		private onSubmit(plateforme: Plateforme) {
			this.onClose();
		}

		@Emit()
		private onClose() {
			this.value = null;
		}
	}
