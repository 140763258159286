













































































































































































































































	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
	import {TiersType, CubageChantier, User, BoisSize} from '@/models';
	import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';

	import storeCubageChantier from '@/stores/modules/cubageChantier';
    import validator from "@/shared/validator";

	import InputBoisType from '@/components/Input/BoisType.vue';
	import InputBoisQuality from '@/components/Input/BoisQuality.vue';
	import InputBoisSize from '@/components/Input/BoisSize.vue';
	import InputMarquage from '@/components/Input/Marquage.vue';
	import {Stored} from 'vue-stored-prop-decorator/index';
	import storeUser from '@/stores/modules/user';
    import CrudChauffeurSearch from '@/components/Crud/Chauffeur/Search.vue';

	@Component({
		components: {
			InputBoisType,
			InputBoisQuality,
			InputBoisSize,
			InputMarquage,
			CrudTiersSearch,
			CrudChauffeurSearch,
		}
	})
	export default class CrudCubageChantierEdit extends AbstractEditVue<CubageChantier> {

        validator = validator
		private TiersType = TiersType;
		private restaAdebarder:boolean = false;

		public constructor() {
			super(
				storeCubageChantier,
				'Cubage modifié',
				'Cubage créé',
			);
		}

		public onChange ()
		{
		  this.item.resteADebarder = this.restaAdebarder;
		}

		public async mounted(): Promise<void> {
			await super.mounted();
			
			if (this.item && !this.item.id)
			{
				//this.item.coutM3 = this.item.chantier.coutM3Defaut;

				var oB = new BoisSize();
				oB.id = 7;
				oB.name = "Entier";
				this.item.boisSize = oB;
			}

			if (this.item)
			{
				this.restaAdebarder = this.item.resteADebarder;
			}
		}

		private onChangeVolumeInner(): void {
			if (this.item.volumeInner != null) {
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
				if (this.item.coefStere) {
					this.item.volumeStere = Math.round(this.item.volumeInner / this.item.coefStere * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangeVolumeOutter(): void {
			if (this.item.volumeOutter) {
				if (this.item.coef) {
					this.item.volumeInner = Math.round(this.item.volumeOutter * this.item.coef * 100 ) / 100;
				} 
				if (this.item.coefStere) {
					this.item.volumeStere = Math.round(this.item.volumeInner / this.item.coefStere * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangeVolumeStere(): void {
			if (this.item.volumeStere) {
				if (this.item.coefStere) {
					this.item.volumeInner = Math.round(this.item.volumeStere * this.item.coefStere * 100 ) / 100;
				} 
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangeCoef(): void {
			if (this.item.coef) {
				if (this.item.volumeOutter) {
					this.item.volumeInner = Math.round(this.item.volumeOutter * this.item.coef * 100 ) / 100;
				} 
				if (this.item.coefStere) {
					this.item.volumeStere = Math.round(this.item.volumeInner / this.item.coefStere * 100 ) / 100;
				} 
			}
			this.$forceUpdate(); 
		}

		private onChangeCoefStere(): void {
			if (this.item.coefStere) {
				if (this.item.volumeStere) {
					this.item.volumeInner = Math.round(this.item.volumeStere * this.item.coefStere * 100 ) / 100;
				} 
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
			}
			this.$forceUpdate(); 
		}
}
