import Vue from 'vue';
import Vuex, {ActionContext, Store} from 'vuex';
import {ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutation, SetterMutation,
	StandardAction
} from '@/shared/utils';
import {CubageChantierRestant, BoisQuality, BoisSize, BoisType,Marquage} from '@/models';
import {CubageChantierRestantService} from '@/xhttp';

import store from '@/stores';

Vue.use(Vuex);

export class CubageChantierRestantForSearchChantierFilter {

	public boisType:     BoisType = null;
	public boisQuality:  BoisQuality = null;
	public boisSize:     BoisSize = null;
	public marquage:     Marquage = null; // uniquement pour utilisation local
	public bGrume:		boolean = true;
	public bBillon:		boolean = true;
	public bAvecTous:		boolean = true;
	public bAvecAutres:		boolean = true;
	public boisVendu:		boolean = true;
	public boisNonVendu:	boolean = true;
	public chauffeur:		string= '';
	public scierie:		string= '';
	public chantier:		string= '';

	public clear(): void {
		this.boisType = null;
		this.boisQuality = null;
		this.boisSize = null;
		this.marquage = null;
	}

	public toString(): string {
		return JSON.stringify({
			boisType    : this.boisType,
			boisQuality : this.boisQuality,
			boisSize    : this.boisSize,
			//marquage    : this.marquage, // pas besoin car uniquement uitlisation locale
		});
	}
}

class CubageChantierRestantForSearchChantierState extends PaginatorFilterState<CubageChantierRestantForSearchChantierFilter> {
	cubageChantierRestant: CubageChantierRestant = null;
	cubageChantierRestants: ResultType<CubageChantierRestant> = null;
	public constructor() {
		super(new CubageChantierRestantForSearchChantierFilter(), 'cubageChantierRestants', 'cubageChantierRestant');
	}
}

class CubageChantierRestantForSearchChantierStore {

	@XHTTPService(() => CubageChantierRestant)
	private cubageChantierRestantService: CubageChantierRestantService;
	private  _store: Store<any>;

	public state: CubageChantierRestantForSearchChantierState = new CubageChantierRestantForSearchChantierState();

	public mutations = {
		...PaginatorMutation(() => this._store, 'cubageChantierRestants', 'cubageChantierRestant'),
	};

	public actions = {
		...StandardAction<CubageChantierRestant, CubageChantierRestantForSearchChantierState>(() => CubageChantierRestant, 'cubageChantierRestant', [ 'post' ]),
		...PaginatorActionWithFilters<CubageChantierRestant, CubageChantierRestantForSearchChantierState>(
			() => CubageChantierRestant,
			'cubageChantierRestants',
			'cubageChantierRestant'
		),
	};

	public init(store: Store<any>): this {
		this._store = store;
		return this;
	}

}
const instance = new CubageChantierRestantForSearchChantierStore();
const storeCubageChantierRestantForSearchChantier = new Vuex.Store(instance);
instance.init(storeCubageChantierRestantForSearchChantier);

store.registerModule('cubageChantierRestant', storeCubageChantierRestantForSearchChantier);
export default storeCubageChantierRestantForSearchChantier;
