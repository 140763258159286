
















































import {Component, Vue} from '@/shared/component';
import {Plateforme} from '@/models';

import Checked from '@/components/Checked.vue';
import {Prop, Watch} from "vue-property-decorator";
import storePlateforme from "@/stores";

@Component({
	components: {
		Checked,
	}
})
export default class CrudPlateformeShowGeneral extends Vue {
	@Prop({ type: Plateforme, required: true }) public plateforme: Plateforme;
	@Prop({ type: Boolean, default: false }) public forceLoad: boolean;
	
	private item: Plateforme = null;

	public async mounted(): Promise<void> {
		await this.plateformeWatcher();
	}
	
	@Watch('plateforme')
	private async plateformeWatcher(): Promise<void> {
		this.item = null;
		if (this.plateforme) {
			if (this.forceLoad) {
				this.item = await storePlateforme.dispatch('get', this.plateforme.id);
			} else {
				this.item = this.plateforme;
			}
		}
	}
}
