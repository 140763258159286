





























































































import {Component} from '@/shared/component';
import {Contact} from '@/models';
import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';

import storeContact from '@/stores/modules/contact';

import CrudTiersShow from '@/components/Crud/Tiers/Show.vue';
import CrudTiersEdit from '@/components/Crud/Tiers/Edit.vue';

@Component({
	components: {
		CrudTiersShow,
		CrudTiersEdit,
	}
})
export default class CrudContactShow extends AbstractShowVue<Contact> {
	public constructor() {
		super(storeContact);
	}
}
