








































import {Emit, Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Chantier} from '@/models';

import CrudChantierEditDonneurOrdre from '@/components/Crud/Chantier/EditDonneurOrdre.vue';

@Component({
	components: {
		CrudChantierEditDonneurOrdre,
	}
})
export default class CrudChantierEditDialogDonneurOrdre extends Vue {
	@InOut({ type: Chantier  , isVModel: true }) private value!: Chantier;
	@Prop({ type: Boolean, default: false}) showOnClose: boolean;
	@Prop({ type: Boolean, default: false}) noRefresh: boolean;

	@Emit()
	private onSubmit(chantier: Chantier) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}		
}
