
































import {Emit} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Parcelle} from '@/models';

import CrudParcelleShow from '@/components/Crud/Parcelle/Show.vue';

@Component({
	components: {
		CrudParcelleShow,
	}
})
export default class CrudParcelleShowDialog extends Vue {
	@InOut({ type: Parcelle  , isVModel: true }) private value!: Parcelle;
	
	@Emit()
	public onClose() {
		this.value = null;
		
	}
}
