







































	import {Emit} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';

	import {LivraisonPlateformePourChauffeur} from '@/models';

	import CrudLivraisonPlateformePourChauffeurEdit from '@/components/Crud/LivraisonPlateformePourChauffeur/Edit.vue';

	@Component({
		components: {
			CrudLivraisonPlateformePourChauffeurEdit,
		}
	})
	export default class CrudLivraisonPlateformePourChauffeurEditDialog extends Vue {
		@InOut({ type: LivraisonPlateformePourChauffeur  , isVModel: true }) private value!: LivraisonPlateformePourChauffeur;

		@Emit()
		private onSubmit(livraisonPlateformePourChauffeur: LivraisonPlateformePourChauffeur) {
			this.onClose();
		}

		@Emit()
		private onClose() {
			this.value = null;
		}

}
