

















import {Prop} from "vue-property-decorator";
import {ObjectConstructor} from 'gollumts-objecttype';
import {Component, Vue} from '@/shared/component';

import {Contrat} from '@/models';

import ListReduce from '@/components/Crud/ListReduce.vue';
import CrudContratShow from '@/components/Crud/Contrat/Show.vue';

@Component({
	components: {
		ListReduce,
	}
})
export default class CrudContratListReduce extends Vue {
	@Prop({ type: Array }) private items!: Contrat[];
	private showComponent: ObjectConstructor<Vue> = CrudContratShow;
}
