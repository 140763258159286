







































import {Emit, Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Chauffeur, Tiers} from '@/models';

import CrudChauffeurEdit from '@/components/Crud/Chauffeur/Edit.vue';

@Component({
	components: {
		CrudChauffeurEdit,
	}
})
export default class CrudChauffeurEditDialog extends Vue {
	@InOut({ type: Chauffeur  , isVModel: true }) private value!: Chauffeur;
	@Prop({ type: Tiers, default: null}) tiers: Tiers;

	@Emit()
	private onSubmit(chauffeur: Chauffeur) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}
}
