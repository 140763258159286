




























import {Component, Vue} from '@/shared/component';
import {InOut} from "vue-inout-prop-decorator/index";
import {Prop} from "vue-property-decorator";

@Component()
export default class DialogConfirm extends Vue {
	@InOut({ type: Boolean, isVModel: true, default: true }) private value: boolean;
	@Prop({ type: String, default: '' }) private title: string;
	@Prop({ type: String, default: '' }) private text: string;
	@Prop({ type: String, default: 'Annuler' }) private cancelText: string;
	@Prop({ type: String, default: 'Valider' }) private confirmText: string;
	@Prop({ type: String, default: 'primary' }) private color: string;

	private cancel():  void {
		this.$emit('cancel');
		this.value = false;
	}

	private confirm():  void {
		this.$emit('confirm');
		this.value = false;
	}
}
