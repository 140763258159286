















































































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {Debardeur} from '@/models';

import CrudDebardeurFilter from '@/components/Crud/Debardeur/Filter.vue';
import CrudDebardeurShowDialog from '@/components/Crud/Debardeur/ShowDialog.vue';
import CrudDebardeurEditDialog from '@/components/Crud/Debardeur/EditDialog.vue';
import CrudDebardeurList from '@/components/Crud/Debardeur/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storeDebardeur, {DebardeurFilter} from '@/stores/modules/debardeur';

@Component({
	components: {
		CrudDebardeurFilter,
		CrudDebardeurShowDialog,
		CrudDebardeurEditDialog,
		CrudDebardeurList,
		CrudDeleteDialog,
	}
})
export default class DebardeurView extends Vue {
	
	@Stored(() => storeDebardeur)
	private debardeurs!: ResultType<Debardeur>;
	
	@Stored(() => storeDebardeur)
	private filters!: DebardeurFilter;

	private showDebardeur: Debardeur = null;
	private editDebardeur: Debardeur = null;
	private deletedDebardeur: Debardeur = null;
	private storeDebardeur = storeDebardeur;

	@Emit()
	private onCreate(): void {
		this.editDebardeur = new Debardeur();
	}

	public mounted(): void {
		console.log("Mounted debardeur");
		this.filters.clear();
	}

	private refresh(): void {
		storeDebardeur.dispatch('getC', { filters: true });
	}
}
