







































import {Emit, Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Debardeur, Tiers} from '@/models';

import CrudDebardeurEdit from '@/components/Crud/Debardeur/Edit.vue';

@Component({
	components: {
		CrudDebardeurEdit,
	}
})
export default class CrudDebardeurEditDialog extends Vue {
	@InOut({ type: Debardeur  , isVModel: true }) private value!: Debardeur;
	@Prop({ type: Tiers, default: null}) tiers: Tiers;

	@Emit()
	private onSubmit(debardeur: Debardeur) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}
}
