import Vue from 'vue';
import Vuex from 'vuex';
import {ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutation,
	StandardAction
} from '@/shared/utils';
import {Marquage} from '@/models';
import {MarquageService} from '@/xhttp';
import store from '@/stores';

Vue.use(Vuex);

export class MarquageFilter {
	
	public name: string = '';
	
	public toString(): string {
		return JSON.stringify(this);
	}
}

class MarquageState extends PaginatorFilterState<MarquageFilter> {
	public marquage: Marquage = null;
	public marquages: ResultType<Marquage> = null;
	public marquagesAll: ResultType<Marquage> = null;
	public constructor() {
		super(new MarquageFilter(), 'marquages', 'marquage');
	}
}

class MarquageStore {
	
	@XHTTPService(() => Marquage)
	private marquageService: MarquageService;
	
	public state: MarquageState = new MarquageState();
	
	public mutations = {
		...PaginatorMutation(() => storeMarquage, 'marquages', 'marquage'),
	};
	
	public actions = {
		...StandardAction<Marquage, MarquageState>(() => Marquage, 'marquage'),
		...PaginatorActionWithFilters<Marquage, MarquageState>(() => Marquage, 'marquages', 'marquage'),
	};
	
}
const instance = new MarquageStore();
const storeMarquage = new Vuex.Store(instance);
store.registerModule('marquage', storeMarquage);
export default storeMarquage;
