
































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {BoisQuality} from '@/models';

import storeBoisQuality from '@/stores/modules/boisQuality';

import InputDatePicker from '@/components/Input/DatePicker.vue';
import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';

@Component({
	components: {
		InputDatePicker,
		CrudTiersSearch,
	}
})
export default class CrudBoisQualityEdit extends AbstractEditVue<BoisQuality> {
	public constructor() {
		super(
			storeBoisQuality,
			'Type de bois modifié',
			'Type de bois créé',
		);
	}
}
