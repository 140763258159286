








































import {Emit, Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Chantier} from '@/models';

import CrudChantierRapideEdit from '@/components/Crud/Chantier/EditChantierRapide.vue';

@Component({
	components: {
		CrudChantierRapideEdit,
	}
})
export default class CrudEditChantierRapideDialog extends Vue {
	@InOut({ type: Chantier  , isVModel: true }) private value!: Chantier;
	@Prop({ type: Boolean, default: false}) showOnClose: boolean;
	@Prop({ type: Boolean, default: false}) noRefresh: boolean;

	@Emit()
	private onSubmit(chantier: Chantier) {
        //console.log("Submit dialog");
		if (this.showOnClose) {
			this.$router.push({name: 'chantier-show', params: { id : this.value.id.toString() }});
		}
		this.onClose();
	}

	@Emit()
	private onClose() {
		if (
			this.value.id && (
				this.$route.name !== 'chantier-show' ||
				this.$route.params["id"] !== this.value.id.toString()
			)
		) {
			this.$router.push({name: 'chantier-show', params: { id : this.value.id.toString() }});
		}
		this.value = null;
	}
}
