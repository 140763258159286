import Vue from 'vue';
import Vuex, {Store} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {NumberFilter} from '@/shared/filter';
import {DateFilter} from '@/shared/filter';
import {CubageLivraison, Tiers} from '@/models';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {Livraison} from '@/models';
import {LivraisonService} from '@/xhttp';
import store from '@/stores';


Vue.use(Vuex);

export class LivraisonFilter {

	public date: DateFilter = new DateFilter();
	public scierie: Tiers[] = [];
	public chauffeurText: string = '';
	public bl: string = '';

	public clear(): void {
		this.date = new DateFilter();
		this.scierie = [];
		this.chauffeurText = '';
		this.bl = '';
	}

	public toString(): string {
		return JSON.stringify({
			date: this.date,
			chauffeurText  : this.chauffeurText,
			scierie: this.scierie.map(p => p.id),
			bl: this.bl,
		});
	}
}

class LivraisonState extends PaginatorFilterState<LivraisonFilter> {
	// infos pour la creation des livraisons et cubages
	public CounterCreation: number = 0;
	public nextcubage_meme_chantier : boolean = false;
	public nextcubage_autre_chantier : boolean = false;
	public PreviousCubageLivraison : CubageLivraison = null;

	public livraison: Livraison = null;
	public livraisons: ResultType<Livraison> = null;
	public constructor() {
		super(new LivraisonFilter(), 'livraisons', 'livraison', 'id', AscDesc.DESC, 20);
	}
}

class LivraisonStore {

	@XHTTPService(() => Livraison)
	private livraisonService: LivraisonService;
	private  _store: Store<any>;

	public state: LivraisonState = new LivraisonState();

	public mutations = {
		...PaginatorMutationFilter(() => storeLivraison, 'livraisons', 'livraison'),
	};

	public actions = { 
		...PaginatorActionWithFilters<Livraison, LivraisonState>(() => Livraison, 'livraisons', 'livraison'),
		...StandardAction<Livraison, LivraisonState>(() => Livraison, 'livraison'),
	};

	public init(store: Store<any>): this {
		this._store = store;
		return this;
	}
}

const instance = new LivraisonStore();
const storeLivraison = new Vuex.Store(instance);
instance.init(storeLivraison);

store.registerModule('livraison', storeLivraison);
export default storeLivraison;

