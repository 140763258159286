












































































































































































	import {Prop, Watch} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
    import {Contrat, Parcelle, Prestation} from "@/models";

	import storeParcelle from '@/stores/modules/parcelle';

	import InputZipCode from '@/components/Input/ZipCode.vue';
	import MapParcelle from '@/components/Map/Parcelle.vue';

	@Component({
		components: {
			InputZipCode,
			MapParcelle,
		}
	})
	export default class CrudParcelleEdit extends AbstractEditVue<Parcelle> {

		@Prop({ type: Prestation, default: null }) prestation: Prestation;

		public constructor() {
			super(
				storeParcelle,
				'Parcelle modifiée',
				'Parcelle créée',
			);
		}

		@Watch('prestation')
		private contratWatcher(newValue:Contrat, oldValue: Contrat) {
			if (this.prestation && this.item) {
				this.item.prestation = this.prestation;
			}
		}

		protected async refresh(): Promise<void> {
			await super.refresh();
			if (this.prestation) {
				this.item.prestation = this.prestation;
			}
		}
	}
