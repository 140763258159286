









































import {Emit} from 'vue-property-decorator';
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import CrudDeleteDialog from "@/components/Crud/DeleteDialog.vue";
import {Stored} from "vue-stored-prop-decorator/index";
import storeCubageLivraisonPlateforme from "@/stores/modules/cubageLivraisonPlateforme";
import {CubageLivraison, CubageLivraisonPlateforme} from "@/models";
import CrudCubageLivraisonListRecap from '@/components/Crud/CubageLivraison/ListRecap.vue';

@Component({
	components: {
		CrudDeleteDialog,
		CrudCubageLivraisonListRecap
	}
})
export default class CrudLivraisonListLivraisonDialog extends Vue {
	@Stored(() => storeCubageLivraisonPlateforme)
	private cubageLivraisonPlateforme: CubageLivraisonPlateforme;
	@InOut({ type: CubageLivraison  , isVModel: true }) private value!: CubageLivraison;


	@Emit()
	private onSubmit() {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}


}
