
















import {Component} from '@/shared/component';
import {AbstractSearchVue} from '@/components/Abstract/AbstractSearchVue';

import InputSearchEntity from '@/components/Input/SearchEntity.vue';
import CrudAbatteurFilter from '@/components/Crud/Abatteur/Filter.vue';
import CrudAbatteurListForSelect from '@/components/Crud/Abatteur/ListForSelect.vue';

@Component({
	components: {
		CrudAbatteurFilter,
		CrudAbatteurListForSelect,
		InputSearchEntity,
	}
})
export default class CrudAbatteurSearch extends AbstractSearchVue {
	private filterComponent = CrudAbatteurFilter;
	private listComponent = CrudAbatteurListForSelect;
}
