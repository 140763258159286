




























	import {Prop} from "vue-property-decorator";
	import {Component, Vue} from '@/shared/component';

	import storeChantier from '@/stores/modules/chantier';
	
  import Checked from '@/components/Checked.vue';
	import CrudList from '@/components/Crud/List.vue';
	import TableHead from '@/components/Paginate/TableHead.vue';
	import TableCell from '@/components/Paginate/TableCell.vue';

	@Component({
		components: {
      Checked,
			CrudList,
			TableHead,
			TableCell,
		}
	})
	export default class CrudChantierListForSelect extends Vue {

		@Prop({type: Boolean, default: true  }) private paginate!: boolean;
		@Prop({type: Boolean, default: false }) private dense!   : boolean;

		private storeChantier = storeChantier;
	}
