





























































import {Prop} from "vue-property-decorator";
import {InOut} from "vue-inout-prop-decorator"
import {Component, Vue} from '@/shared/component';
import {Form} from "@/shared/form";
import {Deposit} from "@/models";

import LoopForm from "@/shared/form/LoopForm.vue";
import InputDatePicker from './DatePicker.vue';

@Component({
	components: {
		LoopForm,
		InputDatePicker
	}
})
export default class InputDepositList extends Vue {
	
	@InOut({ type: Array  , isVModel: true }) private value!: Deposit[];
	@Prop ({ type: Boolean, default: false }) private requiredDtDepot!: boolean;
	@Prop ({ type: Boolean, default: false }) private requiredValue!: boolean;

	private get form(): Form {
		return Form.searchParentForm(this);
	}

	private get formName(): string {
		return Form.searchParentName(this);
	}
	
	private clearForm(): void {
		if (this.form) {
			this.form.clearErrors(this.formName);
			this.form.clearErrors(new RegExp('^deposits\\[\\d\\]'));
		}
	}
	
	private add(): void {
		this.clearForm();
		this.value.push(new Deposit());
		this.$emit('input', this.value);
	}
	
	private remove(index: number): void {
		this.value.splice(index, 1);
	}
}
