












































	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
	import {Prestation, User} from '@/models';

	import storePrestation from '@/stores/modules/prestation';
    import validator from "@/shared/validator";

	import {Stored} from 'vue-stored-prop-decorator/index';
	import storeUser from '@/stores/modules/user';

	import CrudDocumentPrestationCubageUploader from '@/components/Crud/DocumentPrestationCubage/Uploader.vue';
	import CrudDocumentPrestationCubageShow from '@/components/Crud/DocumentPrestationCubage/Show.vue';
	
	@Component({
		components: {
			CrudDocumentPrestationCubageUploader,
			CrudDocumentPrestationCubageShow,
		}
	})
	export default class CrudPrestationEditDocumentCubage extends AbstractEditVue<Prestation> {

        validator = validator
	    
		public constructor() {
			super(
				storePrestation,
				'Prestation modifié',
				'Prestation créé',
			);
		}
	}
