



































	import {Emit} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';

	import storePrestation from '@/stores/modules/prestation';

	import InputParcellesList from '@/components/Input/ParcellesList.vue';
    import {Prestation} from "@/models";
	import {Parcelle} from "@/models";

	@Component({
		components: {
            InputParcellesList,
		}
	})
	export default class CrudPrestationEditParcelles extends AbstractEditVue<Prestation> {
		public constructor() {
			super(
				storePrestation,
				'Prestation modifié',
				'Prestation créé',
			);
		}

		private newParcelle(parcelles: Parcelle[]) {
			if (this.item.parcelles.length)
			{
				this.item.commune = this.item.parcelles[this.item.parcelles.length - 1].town;
			}
		}

	}
