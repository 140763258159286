






















































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Tiers, TiersType} from '@/models';

import storeTiers from '@/stores/modules/tiers';

import CrudAddressEdit from '@/components/Crud/Address/Edit.vue';

@Component({
	components: {
		CrudAddressEdit,
	}
})
export default class CrudTiersAdressesEdit extends AbstractEditVue<Tiers> {

	public constructor() {
		super(
			storeTiers,
			'Tiers modifié',
			'Tiers créé',
		);
	}

	public async mounted(): Promise<void> {
		await super.mounted();
	}

	public async refresh(): Promise<void> {
		await super.refresh();
	}

}
