





































	import {Emit} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
  import {Chantier, Chauffeur} from '@/models';
	import storeChantier from '@/stores/modules/chantier';

  import {Watch} from 'vue-property-decorator';
  import CrudChauffeurSearch from '@/components/Crud/Chauffeur/Search.vue';
	import axios from "axios";
	import storeToken from "@/stores/modules/token";
	import {Token, User} from "@/models";
	import {Stored} from 'vue-stored-prop-decorator';

	@Component({
		components: {
			CrudChauffeurSearch,
		}
	})
	export default class CrudChantierChauffeurEdit extends AbstractEditVue<Chantier> {

		@Stored(() => storeToken) private token: Token;
    private chauffeur: Chauffeur = null;
    private pourBillons: Boolean = false;
    private pourGrumes: Boolean = false;

		public constructor() {
			super(
				storeChantier,
				'Chauffeur affecté',
				'Chantier créé',
			);
		}


		protected async onSubmit(): Promise<void> {
			await axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/chantiers/affecter_chauffeur/" + this.item.id + "/" + this.chauffeur.id + "/" + this.pourBillons + "/" + this.pourGrumes + "?token=" + this.token.id, {});

      await super.onSubmit();
		}

		public async mounted(): Promise<void> {
      await super.mounted();
      
		}
		
	}
