

































































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {BoisSize} from '@/models';

import CrudBoisSizeFilter from '@/components/Crud/BoisSize/Filter.vue';
import CrudBoisSizeEditDialog from '@/components/Crud/BoisSize/EditDialog.vue';
import CrudBoisSizeList from '@/components/Crud/BoisSize/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storeBoisSize from '@/stores/modules/boisSize';

@Component({
	components: {
		CrudBoisSizeFilter,
		CrudBoisSizeEditDialog,
		CrudBoisSizeList,
		CrudDeleteDialog,
	}
})
export default class BoisSizeView extends Vue {
	
	@Stored(() => storeBoisSize)
	private boisSizes!: ResultType<BoisSize>;
	
	private editBoisSize: BoisSize = null;
	private deletedBoisSize: BoisSize = null;
	private storeBoisSize = storeBoisSize;
	
	@Emit()
	private onCreate(): void {
		this.editBoisSize = new BoisSize();
	}

	private refresh(): void {
		storeBoisSize.dispatch('getC', { filters: true });
	}
}
