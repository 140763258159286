
















import {Component} from '@/shared/component';
import {AbstractSearchVue} from '@/components/Abstract/AbstractSearchVue';

import InputSearchEntity from '@/components/Input/SearchEntity.vue';
import CrudContactFilter from '@/components/Crud/Contact/Filter.vue';
import CrudContactList from '@/components/Crud/Contact/List.vue';

@Component({
	components: {
		CrudContactFilter,
		CrudContactList,
		InputSearchEntity,
	}
})
export default class CrudContactSearch extends AbstractSearchVue {
	private filterComponent = CrudContactFilter;
	private listComponent = CrudContactList;
}
