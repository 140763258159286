import Vue from 'vue';
import Vuex from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {NumberFilter} from '@/shared/filter';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {Abatteur, Tiers} from '@/models';
import {AbatteurService} from '@/xhttp';
import store from '@/stores';

Vue.use(Vuex);

export class AbatteurFilter {
	
	firstName: string = '';
	lastName: string = '';
	
	public clear(): void {
		this.firstName = '';
		this.lastName = '';
	}

	public toString(): string {
		return JSON.stringify({
			firstName: this.firstName,
			lastName : this.lastName,
		});
	}
}

class AbatteurState extends PaginatorFilterState<AbatteurFilter> {
	public abatteur: Abatteur = null;
	public abatteurs: ResultType<Abatteur> = null;
	public constructor() {
		super(new AbatteurFilter(), 'abatteurs', 'abatteur', 'id', AscDesc.DESC, 50);
	}
}

class AbatteurStore {
	
	@XHTTPService(() => Abatteur)
	private abatteurService: AbatteurService;
	
	public state: AbatteurState = new AbatteurState();
	
	public mutations = {
		...PaginatorMutationFilter(() => storeAbatteur, 'abatteurs', 'abatteur'),
	};
	
	public actions = {
		...PaginatorActionWithFilters<Abatteur, AbatteurState>(() => Abatteur, 'abatteurs', 'abatteur'),
		...StandardAction<Abatteur, AbatteurState>(() => Abatteur, 'abatteur'),
	};
	
}
const instance = new AbatteurStore();
const storeAbatteur = new Vuex.Store(instance);
store.registerModule('abatteur', storeAbatteur);
export default storeAbatteur;
