























































































import {Component} from '@/shared/component';
import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';

import {Parcelle} from '@/models';

import storeParcelle from '@/stores/modules/parcelle';

import CrudTiersShow from '@/components/Crud/Tiers/Show.vue';
import CrudTiersEdit from '@/components/Crud/Tiers/Edit.vue';
import MapParcelle from '@/components/Map/Parcelle.vue';

@Component({
	components: {
		CrudTiersShow,
		CrudTiersEdit,
		MapParcelle,
	}
})
export default class CrudParcelleShow extends AbstractShowVue<Parcelle> {
	public constructor() {
		super(storeParcelle);
	}
}
