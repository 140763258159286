import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {XHTTPService} from '@/shared/xhttp';
import {Document, Parcelle} from '@/models';
import {DocumentService} from '@/xhttp';
import store from '@/stores';
import contrat from "@/router/contrat";
import storeContrat from "@/stores/modules/contrat";

Vue.use(Vuex);

class DocumentState {
}

class DocumentStore {

	@XHTTPService(() => Document)
	private documentService: DocumentService;

	public state: DocumentState = new DocumentState();

	public mutations = {
	};

	public actions = {
		async delete(context: ActionContext<DocumentState, any>, document: Document): Promise<Document> {
			try {
				document.contrat.removeDocuments(document);
				return await instance.documentService.delete('/{id}', document);
			} catch (e) {
				storeContrat.dispatch('get', document.contrat.id);
				throw e;
			}
		}
	};

}
const instance = new DocumentStore();
const storeDocument = new Vuex.Store(instance);
store.registerModule('document', storeDocument);
export default storeDocument;
