


































import {Map, LatLng, Icon, LatLngBounds} from "leaflet";
import * as nominatim from 'nominatim-geocode';
import {Component, Vue} from '@/shared/component';
import {InOut} from "vue-inout-prop-decorator/index";
import {Emit, Watch} from "vue-property-decorator";


interface MapResult {
	display_name: string;
	lat: number;
	lon: number;
	boundingbox: number[];
	address: {
		city: string,
		hamlet: string,
		town: string,
		postcode: string,
	};
}

@Component()
export default class MapParcelle extends Vue {

	@InOut({ type: Number , required: false, default: null  }) private lat!: number;
	@InOut({ type: Number , required: false, default: null  }) private lng!: number;
	@InOut({ type: String , required: false, default: null  }) private town!: string;
	@InOut({ type: String , required: false, default: null  }) private lieuxDit!: string;
	@InOut({ type: String , required: false, default: null  }) private zipCode!: string;
	@InOut({ type: Boolean, required: false, default: false }) private readonly!: boolean;

	private map: Map = null;

	private zoom: number = 9;
	private center: LatLng = new LatLng(45.7553939, 4.866961);
	private bounds: LatLngBounds = null;
	private results: MapResult[] = [];
	private focused: MapResult = null;

	private icon: Icon = new Icon({
		iconUrl: '/img/marker.png',
		iconSize: [38, 40],
		iconAnchor: [19, 39]
	});

	private get marker(): LatLng {
		if (this.lat !== null && this.lng !== null) {
			return new LatLng(this.lat, this.lng);
		}
		return null;
	}

	private get markerFocused(): LatLng {
		if (this.focused && this.focused.lat !== null && this.focused.lon !== null) {
			return new LatLng(this.focused.lat, this.focused.lon);
		}
		return null;
	}

	private get search(): string {
		return ((this.town ? this.town : '') + ' ' + (this.lieuxDit ? this.lieuxDit : '') + ' ' + (this.zipCode ? this.zipCode : '')).trim();
	}

	public mounted(): void {
		if (this.lat !== null && this.lng !== null) {
			this.center = new LatLng(this.lat, this.lng);
		}
	}

	private onSearch() {
		this.focused = null;
		nominatim.geocode({
			q: this.search+' France'
		}, (err, results) => {
			if (!err) {
				this.results = results.splice(0, 3);
			}
			// hamlet town, city
		});
	}

	private onFocus(result: MapResult) {
		if (this.readonly) {
			return;
		}
		this.focused = result;
		this.bounds = new LatLngBounds(
			new LatLng(result.boundingbox[0], result.boundingbox[2]),
			new LatLng(result.boundingbox[1], result.boundingbox[3])
		);
	}

	private onReady(map: Map) {
		this.map = map;
	}

	@Emit()
	private onValidate() {
		this.lat = parseFloat(this.focused.lat.toString());
		this.lng = parseFloat(this.focused.lon.toString());
		if (this.focused.address) {
			this.town = this.focused.address.town ? this.focused.address.town : (this.focused.address.city ? this.focused.address.city : this.town);
			this.lieuxDit = this.focused.address.hamlet ? this.focused.address.hamlet : this.lieuxDit;
			this.zipCode = this.focused.address.postcode ? this.focused.address.postcode : this.zipCode;
		}
		this.focused = null;
		this.results = [];
	}

	@Emit()
	private onPlaceMarker(event: any) {
		if (this.readonly) {
			return;
		}
		this.lat = event.latlng.lat;
		this.lng = event.latlng.lng;
	}
}
