



























































import {Emit, Prop, Watch} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';
import {CubageChantier, CubageChantierRestant} from "@/models";
import {Form} from "@/shared/form";
import {InOut} from 'vue-inout-prop-decorator';
import axios from "axios";
import storeToken from "@/stores/modules/token";
import {Token, User} from "@/models"; 
import {Stored} from 'vue-stored-prop-decorator';

@Component({
	components: {
	}
})
export default class CrudCubageChantierVolumeRestantEdit extends Vue {
	@InOut({ type: CubageChantierRestant  , isVModel: true }) private value!: CubageChantierRestant;

	@Stored(() => storeToken) private token: Token;

	private volume: Number = 0;
	private form: Form = new Form;
	private volumeType: string = "M3";

	private async fix(): Promise<void> {
		if (this.value.boisSize.name == "Steres")
		{
			this.volumeType = "Steres";
		}
		else
		{
			this.volumeType = "M3";
		}

		await axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/cubage-chantiers/update-volume-restant/" + this.value.id + "/" +  this.volume + "/" +  this.volumeType + "?token=" + this.token.id, {});

		this.notify('Correction effectuée');
		this.$emit('on-submit', this.value);
		this.onClose();
	}
	
	@Emit()
	public onClose() {
		this.volume = 0;
		this.value = null;
	}

}
