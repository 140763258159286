







































import {Emit, Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Abatteur, Tiers} from '@/models';

import CrudAbatteurEdit from '@/components/Crud/Abatteur/Edit.vue';

@Component({
	components: {
		CrudAbatteurEdit,
	}
})
export default class CrudAbatteurEditDialog extends Vue {
	@InOut({ type: Abatteur  , isVModel: true }) private value!: Abatteur;
	@Prop({ type: Tiers, default: null}) tiers: Tiers;

	@Emit()
	private onSubmit(abatteur: Abatteur) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}
}
