




































































































































	import {Stored} from 'vue-stored-prop-decorator';
	import {Component, Vue} from '@/shared/component';
	import {Emit} from 'vue-property-decorator';
	import {ResultType} from '@/shared/xhttp';
	import axios from "axios";
	import storeToken from "@/stores/modules/token";
	import {Token, User} from "@/models"; 
	import {Prop, Watch} from "vue-property-decorator";
	import {Timeout, WatchObjectProperties} from '@/shared/decorator';

	@Component({
		components: {
		}
	})
	export default class SuiviLivraisonView extends Vue {
		@Stored(() => storeToken) private token: Token;

		private data = '';
		private headers = [
			{ text: 'Bois', value: 'bois', groupable: false, sortable: false },
			{ text: 'Restant à débarder', value: 'volume', groupable: false, sortable: false },
			{ text: 'Débardeur', value: 'debardeur', groupable: false, sortable: false },
			{ text: 'Scierie', value: 'scierie', groupable: false, sortable: false },
			{ text: 'Liens', value: 'liens', groupable: false, sortable: false },
			{ text: 'Remarques', value: 'message_bois', groupable: false, sortable: false },
		];

		private async mounted(): Promise<void> {
			this. LoadData();
		}

		private async LoadData()
		{
			await axios.get(process.env.VUE_APP_BACK_BASE_URL + "/api/cubage-chantiers/resteadebarder?token=" + this.token.id, {}).then(async response => {
				this.data = await response.data;
			});
		}

		formatDate(dateString) {
			if (dateString == '') return '';
			if (dateString == null) return '';

            const date = new Date(dateString);
                // Then specify how you want your dates to be formatted
            return new Intl.DateTimeFormat('default', { month: 'long', day: 'numeric', year: 'numeric', }).format(date);
        }

		itemRowBackground (item){
			if (item.boisVendu)
			{
				return item.grume == 1 ? 'grume_vendu_class' : 'billon_vendu_class';
			}
			else
			{
				return item.grume == 1 ? 'grume_class' : 'billon_class';
			}
		}

	}
