


















































	import {Stored} from "vue-stored-prop-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
  import {Contrat, Token} from '@/models';

	import storeContrat from '@/stores/modules/contrat';
	import storeToken from "@/stores/modules/token";

	@Component({
		components: {
		}
	})
	export default class CrudContratEditAttestation extends AbstractEditVue<Contrat> {

		@Stored(() => storeToken)
		private token: Token;

		public constructor() {
			super(
				storeContrat,
				'Contrat modifié',
				'Contrat créé',
			);
		}
	}
