















































































import {InOut} from 'vue-inout-prop-decorator';
import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Tiers, TiersType} from '@/models';
import CrudAddressEdit from '@/components/Crud/Address/Edit.vue';

import storeTiers from '@/stores/modules/tiers';

import InputPhone from '@/components/Input/Phone.vue';

@Component({
	components: {
		InputPhone,
		CrudAddressEdit,
	}
})
export default class CrudTiersEdit extends AbstractEditVue<Tiers> {

	public constructor() {
		super(
			storeTiers,
			'Tiers modifié',
			'Tiers créé',
		);
	}

	protected async onSubmit(): Promise<void> {
		super.onSubmit();
	}

	public async mounted(): Promise<void> {
		await super.mounted();
	}

	public async refresh(): Promise<void> {
		await super.refresh();
		//console.log('route tiers edit = ' + this.$route.name);
		if (this.$route.name === 'tiers-edit') {
			this.$route.meta.title = this.item.type === TiersType.PROPRIETAIRE ? 'Édition  d\'un propriétaire' : 'Édition  d\'une scierie';
		}
	}

}
