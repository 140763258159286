
















































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {Chantier} from '@/models';

import CrudChantierFilter from '@/components/Crud/Chantier/Filter.vue';
import CrudChantierEditDialog from '@/components/Crud/Chantier/EditDialog.vue';
import CrudChantierListMesChantiers from '@/components/Crud/Chantier/ListMesChantiers.vue';

import storeChantier from '@/stores/modules/chantier';

@Component({
	components: {
		CrudChantierFilter,
		CrudChantierListMesChantiers,
		CrudChantierEditDialog,
	}
})
export default class MesChantiersView extends Vue {
	
	@Stored(() => storeChantier)
	private chantiers!: ResultType<Chantier>;
	
	private editChantier: Chantier = null;
	private storeChantier = storeChantier;
	private isNew: boolean = false;
	
	@Emit()
	private onCreate(): void {
		this.isNew = true;
		this.editChantier = new Chantier();
	}

	private refresh(): void {
		this.isNew = false;
		storeChantier.dispatch('getC', { filters: true });
	}
}

