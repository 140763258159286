












































	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
	import {CubagePrestationEstimed, User} from '@/models';

	import storeCubagePrestationEstimed from '@/stores/modules/cubagePrestationEstimed';
    import validator from "@/shared/validator";

	import {Stored} from 'vue-stored-prop-decorator/index';
	import storeUser from '@/stores/modules/user';

	import CrudDocumentCubagePourClientUploader from '@/components/Crud/DocumentCubagePourClient/Uploader.vue';
	import CrudDocumentCubagePourClientShow from '@/components/Crud/DocumentCubagePourClient/Show.vue';
	
	@Component({
		components: {
			CrudDocumentCubagePourClientUploader,
			CrudDocumentCubagePourClientShow,
		}
	})
	export default class CrudCubagePrestationEstimedEditDocument extends AbstractEditVue<CubagePrestationEstimed> {

        validator = validator
	    
		public constructor() {
			super(
				storeCubagePrestationEstimed,
				'Cubage modifié',
				'Cubage créé',
			);
		}
	}
