









































































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {Plateforme} from '@/models';

import CrudPlateformeFilter from '@/components/Crud/Plateforme/Filter.vue';
import CrudPlateformeEditDialog from '@/components/Crud/Plateforme/EditDialog.vue';
import CrudPlateformeList from '@/components/Crud/Plateforme/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storePlateforme from '@/stores/modules/plateforme';

@Component({
	components: {
		CrudPlateformeFilter,
		CrudPlateformeEditDialog,
		CrudPlateformeList,
		CrudDeleteDialog,
	}
})
export default class PlateformeView extends Vue {

	@Stored(() => storePlateforme)
	private livraisons!: ResultType<Plateforme>;

	private editPlateforme: Plateforme = null;
	private deletedLivraison: Plateforme = null;
	private storePlateforme = storePlateforme;

	private refresh(): void {
		storePlateforme.dispatch('getC');
	}
}
