







































import {Emit, Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Contrat} from '@/models';

import CrudContratEditAttestation from '@/components/Crud/Contrat/EditAttestation.vue';

@Component({
	components: {
		CrudContratEditAttestation,
	}
})
export default class CrudContratEditDialog extends Vue {
	@InOut({ type: Contrat  , isVModel: true }) private value!: Contrat;
	
	@Emit()
	private onSubmit(contrat: Contrat) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}
}
