import Vue from 'vue';
import Vuex, {ActionContext, Store} from 'vuex';
import {ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutation, SetterMutation,
	StandardAction
} from '@/shared/utils';
import {CubageChantierRestant, Chantier, BoisType, BoisQuality, BoisSize} from '@/models';
import {CubageChantierRestantService} from '@/xhttp';

import store from '@/stores';
import storeCubageChantierRestantForSearchChantier from "@/stores/modules/cubageChantierRestantForSearchChantier";

Vue.use(Vuex);

export class CubageChantierRestantFilter {

	boisType: boolean = true;
	boisQuality: boolean = true;
	boisSize: boolean = true;
	marquage: boolean = true;
	nullValue: boolean = false;
	groupChantier: boolean = false;
	chantier: Chantier[] = [];
	coupeChantierFinie: boolean = false;
	public state: boolean[] = [];

	public clear(): void {
		this.boisType = true;
		this.boisQuality = true;
		this.boisSize = true;
		this.marquage = true;
		this.nullValue = false;
		this.groupChantier = false;
		this.chantier = [];
		this.coupeChantierFinie = false;
	}

	public get allBois(): boolean {
		return this.boisType && this.boisQuality && this.boisSize && this.marquage;
	}

	public toString(): string {
		return JSON.stringify({
			boisType   : this.boisType,
			boisQuality: this.boisQuality,
			boisSize   : this.boisSize,
			marquage   : this.marquage,
			nullValue  : this.nullValue,
			groupChantier: this.groupChantier,
			chantier : this.chantier.map(t => t.id),
			coupeChantierFinie: this.coupeChantierFinie,
			state  : this.state,
		});
	}
}

class CubageChantierRestantState extends PaginatorFilterState<CubageChantierRestantFilter> {
	cubageChantierRestant: CubageChantierRestant = null;
	cubageChantierRestants: ResultType<CubageChantierRestant> = null;
	cubageChantierRestantsForSearchChantier: ResultType<CubageChantierRestant> = null;
	public constructor() {
		super(new CubageChantierRestantFilter(), 'cubageChantierRestants', 'cubageChantierRestant');
	}
}

class CubageChantierRestantStore {

	@XHTTPService(() => CubageChantierRestant)
	private cubageChantierRestantService: CubageChantierRestantService;
	private  _store: Store<any>;

	public state: CubageChantierRestantState = new CubageChantierRestantState();

	public mutations = {
		...PaginatorMutation(() => this._store, 'cubageChantierRestants', 'cubageChantierRestant'),
		...SetterMutation([ 'cubageChantierRestantsForSearchChantier' ])
	};

	public actions = {
		...StandardAction<CubageChantierRestant, CubageChantierRestantState>(() => CubageChantierRestant, 'cubageChantierRestant', [ 'post' ]),
		...PaginatorActionWithFilters<CubageChantierRestant, CubageChantierRestantState>(
			() => CubageChantierRestant,
			'cubageChantierRestants',
			'cubageChantierRestant'
		),
		/*async getCTotalChantier(context: ActionContext<CubageChantierRestantState, any>, options: any): Promise<ResultType<CubageChantierRestant>> {
			const cubageChantierRestantsChantier = await instance.cubageChantierRestantService.getCTotalChantier(
				storeChantier.state.chantier,
				options && typeof options.limit !== 'undefined' ? options.limit : context.state.limit,
				options && typeof options.page !== 'undefined' ? options.page : context.state.page,
				options && typeof options.order !== 'undefined' ? options.order : context.state.order,
				options && typeof options.direction !== 'undefined' ? options.direction : context.state.direction,
				options && options.filters ? { filters: ( options.filters === true ? context.state.filters : options.filters ) } : {},
			);
			context.commit('setCubageChantierRestantsChantier', cubageChantierRestantsChantier);
			return cubageChantierRestantsChantier;
		},*/

		async getByBois(context: ActionContext<CubageChantierRestantState, any>): Promise<ResultType<CubageChantierRestant>> {
			var boisType: BoisType = null;
			var boisQuality: BoisQuality = null;
			var boisSize: BoisSize = null;
		
			if (storeCubageChantierRestantForSearchChantier.state.filters)
			{
				if (storeCubageChantierRestantForSearchChantier.state.filters.boisType)
				{
					boisType = storeCubageChantierRestantForSearchChantier.state.filters.boisType;
				}				
				if (storeCubageChantierRestantForSearchChantier.state.filters.boisQuality)
				{
					boisQuality = storeCubageChantierRestantForSearchChantier.state.filters.boisQuality;
				}
				if (storeCubageChantierRestantForSearchChantier.state.filters.boisSize)
				{
					boisSize = storeCubageChantierRestantForSearchChantier.state.filters.boisSize;
				}
			}

			const cubageChantierRestantsResult = await instance.cubageChantierRestantService.getByBois(boisType, boisQuality, boisSize);
			context.commit('setCubageChantierRestantsForSearchChantier', cubageChantierRestantsResult);
			return cubageChantierRestantsResult;
		},

	};

	public init(store: Store<any>): this {
		this._store = store;
		return this;
	}

}
const instance = new CubageChantierRestantStore();
const storeCubageChantierRestant = new Vuex.Store(instance);
instance.init(storeCubageChantierRestant);

store.registerModule('cubageChantierRestant', storeCubageChantierRestant);
export default storeCubageChantierRestant;
