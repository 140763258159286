import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import {ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutation, SetterMutation,
	StandardAction
} from '@/shared/utils';
import {DateFilter} from '@/shared/filter';
import {Contrat, Parcelle, Tiers} from '@/models';
import {ParcelleService} from '@/xhttp';
import store from '@/stores';


Vue.use(Vuex);

export class ParcelleFilter {

	public numeroParcelle: string = '';
	public numeroSection: string = '';
	public zipCode: string = '';
	public town: string = '';
	public lieuxDit: string = '';
	public createdAt: DateFilter = new DateFilter();

	public clear(): void {
		this.numeroParcelle = '';
		this.numeroSection = '';
		this.zipCode = '';
		this.town = '';
		this.lieuxDit = '';
		this.createdAt = new DateFilter();
	}

	public toString(): string {
		return JSON.stringify({
			numeroParcelle: this.numeroParcelle,
			numeroSection : this.numeroSection,
			zipCode: this.zipCode,
			town : this.town,
			lieuxDit: this.lieuxDit,
			createdAt     : this.createdAt,
		});
	}
}

class ParcelleState extends PaginatorFilterState<ParcelleFilter> {
	public parcelle: Parcelle = null;
	public last: Parcelle = null;
	public parcelles: ResultType<Parcelle> = null;
	public constructor() {
		super(new ParcelleFilter(), 'parcelles', 'parcelle');
	}
}

class ParcelleStore {

	@XHTTPService(() => Parcelle)
	private parcelleService: ParcelleService;

	public state: ParcelleState = new ParcelleState();

	public mutations = {
		...PaginatorMutation(() => storeParcelle, 'parcelles', 'parcelle'),
		...SetterMutation([ 'last' ])
	};

	public actions = {
		...StandardAction<Parcelle, ParcelleState>(() => Parcelle, 'parcelle'),
		...PaginatorActionWithFilters<Parcelle, ParcelleState>(() => Parcelle, 'parcelles', 'parcelle'),
		async getLast(context: ActionContext<ParcelleState, any>): Promise<Parcelle> {
			const last = await instance.parcelleService.last();
			context.commit('setLast', last);
			return last;
		}
	};

}
const instance = new ParcelleStore();
const storeParcelle = new Vuex.Store(instance);
store.registerModule('parcelle', storeParcelle);
export default storeParcelle;
