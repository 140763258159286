
































import {Emit} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Chauffeur} from '@/models';

import CrudChauffeurShow from '@/components/Crud/Chauffeur/Show.vue';

@Component({
	components: {
		CrudChauffeurShow,
	}
})
export default class CrudChauffeurShowDialog extends Vue {
	@InOut({ type: Chauffeur  , isVModel: true }) private value!: Chauffeur;
	
	@Emit()
	public onClose() {
		this.value = null;
	}
}
