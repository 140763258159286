





































import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {ResultType} from '@/shared/xhttp';
import {Prestation} from '@/models';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';

import CrudPrestationFilter from '@/components/Crud/Prestation/Filter.vue';
import CrudPrestationShowDialog from '@/components/Crud/Prestation/ShowDialog.vue';
import CrudPrestationEditDialog from '@/components/Crud/Prestation/EditDialog.vue';
import CrudPrestationList from '@/components/Crud/Prestation/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storePrestation, {PrestationFilter} from '@/stores/modules/prestation';

@Component({
	components: {
		CrudPrestationFilter,
		CrudPrestationEditDialog,
		CrudPrestationList,
		CrudDeleteDialog,
	}
})
export default class PrestationView extends Vue {

	@Stored(() => storePrestation)
	private prestations!: ResultType<Prestation>;

	@Stored(() => storePrestation)
	private filters!: PrestationFilter;

	private editPrestation: Prestation = null;
	private deletedPrestation: Prestation = null;
	private storePrestation = storePrestation;

	private total_regle: number = 12.5;
	private total_cubage: number = 12.5;

	public mounted(): void {
		this.filters.clear();
		//this.filters.finish = [ false ];
		this.filters.hasChantier = [ false ];
		//console.log("mounted");
	}

	@Watch('prestations')
	public watcher(): void {
		//console.log("filter");
		// on calcul le total
		//console.log(this.prestations);
		this.total_regle = 0;
		this.total_cubage = 0;
		for (const prestation of this.prestations.data) {
			this.total_regle = this.total_regle + prestation.montantRegler;
			this.total_cubage = this.total_cubage + Number(prestation.cubageEstimatif);
		}

	}

	private refresh(): void {
		storePrestation.dispatch('getC', { filters: true });
	}



}
